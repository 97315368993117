/* eslint-disable */
import { stringAt } from '../core/alphabet';
import { getFontSizePxByPt } from '../core/font';
import _cell from '../core/cell';
import { formatm } from '../core/format';

import {
  Draw, DrawBox, thinLineWidth, npx,
} from '../canvas/draw';
import { Parser } from 'hot-formula-parser';
import {
  isEnabled as isDarkReaderEnabled
} from 'darkreader';

// gobal var
var bodyBackgroundColor;
const cellPaddingWidth = 5;
let tableFixedHeaderCleanStyle = { fillStyle: '#242525' };
const tableGridStyle = {
  fillStyle: '#fff',
  lineWidth: thinLineWidth(),
  strokeStyle: '#c0c0c0',
};


const formulaParser = new Parser();
function tableFixedHeaderStyle() {
  return {
    textAlign: 'center',
    textBaseline: 'middle',
    font: `500 ${npx(11)}px Arial`,
    fillStyle: '#585757',
    lineWidth: thinLineWidth(),
    strokeStyle: '#8f8f8f',
  };
}

function getDrawBox(data, rindex, cindex, yoffset = 0) {
  const {
    left, top, width, height,
  } = data.cellRect(rindex, cindex);
  return new DrawBox(left, top + yoffset, width, height, cellPaddingWidth);
}
/*
function renderCellBorders(bboxes, translateFunc) {
  const { draw } = this;
  if (bboxes) {
    const rset = new Set();
    // console.log('bboxes:', bboxes);
    bboxes.forEach(({ ri, ci, box }) => {
      if (!rset.has(ri)) {
        rset.add(ri);
        translateFunc(ri);
      }
      draw.strokeBorders(box);
    });
  }
}
*/

export function renderCell(draw, data, rindex, cindex, yoffset = 0, isPrinting = false) {
  // console.log(this.tableDatas);
  const { sortedRowMap, rows, cols } = data;
  if (rows.isHide(rindex) || cols.isHide(cindex)) return;
  let nrindex = rindex;
  if (sortedRowMap.has(rindex)) {
    nrindex = sortedRowMap.get(rindex);
  }

  let cell = data.getCell(nrindex, cindex);
  if (isPrinting && cell === null) {
    cell = { text: '' }
  }
  if (cell === null) return;
  let frozen = false;
  if ('editable' in cell && cell.editable === false) {
    frozen = true;
  }
  // Get the body element
  const bodyElement = document.body;

  // Get the computed background color of the body element


  // Check if the background color matches the desired color ('#242525')
  // if (bodyBackgroundColor === 'rgb(36, 37, 37)' || bodyBackgroundColor === '#242525') {
  //   console.log('Body background color is #242525.');
  //   // Your logic here, e.g., change the color of another element
  // } else {
  //   console.log('Body background color is different.');
  //   // Your logic for a different background color
  // }
  let style = data.getCellStyleOrDefault(nrindex, cindex);
  if (isPrinting) {
    let fontStyle = {
      ...style.font,
      // bold: true,
      size: style.font.size === 10 ? 14 : style.font.size
    };
    if (style.border === undefined) {
      style = {
        ...style,
        border: {
          bottom: ['thin', '#c0c0c0'],
          left: ['thin', '#c0c0c0'],
          right: ['thin', '#c0c0c0'],
          top: ['thin', '#c0c0c0']
        },
        font: fontStyle
      };
    } else {
      style = {
        ...style,
        font: fontStyle
      };
    }
  }
  const dbox = getDrawBox(data, rindex, cindex, yoffset);
  bodyBackgroundColor = window.getComputedStyle(bodyElement).backgroundColor;
  // console.log('style.bgcolor',style.color)
  dbox.bgcolor = bodyBackgroundColor == 'rgb(36, 37, 37)' ? style.bgcolor == 'rgb(255, 255, 255)' || style.bgcolor == '#ffffff' ? '#242525' : style.bgcolor : style.bgcolor
  if (style.border !== undefined) {
    dbox.setBorders(style.border);
    // bboxes.push({ ri: rindex, ci: cindex, box: dbox });
    draw.strokeBorders(dbox);
  }
  draw.rect(dbox, () => {
    // render text
    let cellText = '';
    if (!data.settings.evalPaused) {
      cellText = _cell.render(cell.text || '', formulaParser, (y, x) => (data.getCellTextOrDefault(x, y)), data.allSheets);
    } else {
      cellText = cell.text || '';
    }
    if (style.format) {
      // console.log(data.formatm, '>>', cell.format);
      cellText = formatm[style.format].render(cellText, formulaParser);
    }
    const font = Object.assign({}, style.font);
    font.size = getFontSizePxByPt(font.size);
    // console.log('style:', style);
    draw.text(cellText, dbox, {
      align: style.align,
      valign: style.valign,
      font,
      color: style.color,
      strike: style.strike,
      underline: style.underline,
    }, style.textwrap);
    // error
    const error = data.validations.getError(rindex, cindex);
    if (error) {
      // console.log('error:', rindex, cindex, error);
      draw.error(dbox);
    }
    if (frozen) {
      draw.frozen(dbox);
    }
  });
}

function renderAutofilter(viewRange) {
  const { data, draw } = this;
  if (viewRange) {
    const { autoFilter } = data;
    if (!autoFilter.active()) return;
    const afRange = autoFilter.hrange();
    if (viewRange.intersects(afRange)) {
      afRange.each((ri, ci) => {
        const dbox = getDrawBox(data, ri, ci);
        draw.dropdown(dbox);
      });
    }
  }
}

function renderContent(viewRange, fw, fh, tx, ty) {
  const { draw, data } = this;
  draw.save();
  draw.translate(fw, fh)
    .translate(tx, ty);

  const { exceptRowSet } = data;
  // const exceptRows = Array.from(exceptRowSet);
  const filteredTranslateFunc = (ri) => {
    const ret = exceptRowSet.has(ri);
    if (ret) {
      const height = data.rows.getHeight(ri);
      draw.translate(0, -height);
    }
    return !ret;
  };

  const exceptRowTotalHeight = data.exceptRowTotalHeight(viewRange.sri, viewRange.eri);
  // 1 render cell
  draw.save();
  draw.translate(0, -exceptRowTotalHeight);
  viewRange.each((ri, ci) => {
    renderCell.call(this, draw, data, ri, ci);
  }, ri => filteredTranslateFunc(ri));
  draw.restore();


  // 2 render mergeCell
  const rset = new Set();
  draw.save();
  draw.translate(0, -exceptRowTotalHeight);
  data.eachMergesInView(viewRange, ({ sri, sci, eri }) => {
    if (!exceptRowSet.has(sri)) {
      renderCell.call(this, draw, data, sri, sci);
    } else if (!rset.has(sri)) {
      rset.add(sri);
      const height = data.rows.sumHeight(sri, eri + 1);
      draw.translate(0, -height);
    }
  });
  draw.restore();

  // 3 render autofilter
  renderAutofilter.call(this, viewRange);

  draw.restore();
}

function renderSelectedHeaderCell(x, y, w, h) {
  const { draw } = this;
  draw.save();
  draw.attr({ fillStyle: 'rgba(75, 137, 255, 0.08)' })
    .fillRect(x, y, w, h);
  draw.restore();
}

// viewRange
// type: all | left | top
// w: the fixed width of header
// h: the fixed height of header
// tx: moving distance on x-axis
// ty: moving distance on y-axis
function renderFixedHeaders(type, viewRange, w, h, tx, ty) {
  const bodyElement = document.body;
  bodyBackgroundColor = window.getComputedStyle(bodyElement).backgroundColor;
  const { draw, data } = this;
  const sumHeight = viewRange.h; // rows.sumHeight(viewRange.sri, viewRange.eri + 1);
  const sumWidth = viewRange.w; // cols.sumWidth(viewRange.sci, viewRange.eci + 1);
  const nty = ty + h;
  const ntx = tx + w;

  draw.save();
  // draw rect background
  const tableFixedHeaderCleanStyle = { fillStyle: bodyBackgroundColor == 'rgb(36, 37, 37)' ? '#242525' : '#f8f9fa' };

  draw.attr(tableFixedHeaderCleanStyle);
  if (type === 'all' || type === 'left') draw.fillRect(0, nty, w, sumHeight);
  if (type === 'all' || type === 'top') draw.fillRect(ntx, 0, sumWidth, h);

  const {
    sri, sci, eri, eci,
  } = data.selector.range;
  // console.log(data.selectIndexes);
  // draw text
  // text font, align...
  draw.attr.bgcolor = '#00'
  draw.attr(tableFixedHeaderStyle());
  // y-header-text
  if (type === 'all' || type === 'left') {
    data.rowEach(viewRange.sri, viewRange.eri, (i, y1, rowHeight) => {
      const y = nty + y1;
      const ii = i;
      draw.line([0, y], [w, y]);
      if (sri <= ii && ii < eri + 1) {
        renderSelectedHeaderCell.call(this, 0, y, w, rowHeight);
      }
      draw.fillText(ii + 1, w / 2, y + (rowHeight / 2));
      if (i > 0 && data.rows.isHide(i - 1)) {
        draw.save();
        draw.attr({ strokeStyle: '#c6c6c6' });
        draw.attr({ fillStyle: '#000' });

        draw.line([5, y + 5], [w - 5, y + 5]);
        draw.restore();
      }
    });
    draw.line([0, sumHeight + nty], [w, sumHeight + nty]);
    draw.line([w, nty], [w, sumHeight + nty]);
  }
  // x-header-text
  if (type === 'all' || type === 'top') {
    data.colEach(viewRange.sci, viewRange.eci, (i, x1, colWidth) => {
      const x = ntx + x1;
      const ii = i;
      draw.line([x, 0], [x, h]);
      if (sci <= ii && ii < eci + 1) {
        renderSelectedHeaderCell.call(this, x, 0, colWidth, h);
      }
      draw.fillText(stringAt(ii), x + (colWidth / 2), h / 2);
      if (i > 0 && data.cols.isHide(i - 1)) {
        draw.save();
        draw.attr({ strokeStyle: '#c6c6c6' });
        draw.attr({ backgroundColor: '#00' });

        draw.line([x + 5, 5], [x + 5, h - 5]);
        draw.restore();
      }
    });
    draw.line([sumWidth + ntx, 0], [sumWidth + ntx, h]);
    draw.line([0, h], [sumWidth + ntx, h]);
  }
  draw.restore();
}

function renderFixedLeftTopCell(fw, fh) {
  const { draw } = this;
  draw.save();
  // left-top-cell
  draw.attr({ fillStyle: '#f4f5f8' })
    .fillRect(0, 0, fw, fh);
  draw.restore();
}

function renderContentGrid({
  sri, sci, eri, eci, w, h,
}, fw, fh, tx, ty) {
  const { draw, data } = this;
  const { settings } = data;
  draw.save();
  if (data.pivotData.isEdit && !data.pivotData.isExistingSheet) {
    // console.log('okkkkk data', data.isDarkMode);
    if (!data.isDarkMode) {
      console.log('okkkkk data2222', data.isDarkMode);
      tableGridStyle.strokeStyle = '#0000'
    }
    else {
      tableGridStyle.strokeStyle = '#fff'
    }
  }
  else {
    tableGridStyle.strokeStyle = '#c0c0c0'
  }
  draw.attr(tableGridStyle)
    .translate(fw + tx, fh + ty);
  // const sumWidth = cols.sumWidth(sci, eci + 1);
  // const sumHeight = rows.sumHeight(sri, eri + 1);
  // console.log('sumWidth:', sumWidth);
  // draw.clearRect(0, 0, w, h);
  if (!settings.showGrid) {
    draw.restore();
    return;
  }
  // console.log('rowStart:', rowStart, ', rowLen:', rowLen);
  data.rowEach(sri, eri, (i, y, ch) => {
    // console.log('y:', y);
    if (i !== sri) draw.line([0, y], [w, y]);
    if (i === eri) draw.line([0, y + ch], [w, y + ch]);
  });
  data.colEach(sci, eci, (i, x, cw) => {
    if (i !== sci) draw.line([x, 0], [x, h]);
    if (i === eci) draw.line([x + cw, 0], [x + cw, h]);
  });
  draw.restore();
}

function renderFreezeHighlightLine(fw, fh, ftw, fth) {
  const { draw, data } = this;
  const twidth = data.viewWidth() - fw;
  const theight = data.viewHeight() - fh;
  draw.save()
    .translate(fw, fh)
    .attr({ strokeStyle: '#f2240099' });
  draw.line([0, fth], [twidth, fth]);
  draw.line([ftw, 0], [ftw, theight]);
  draw.restore();
}

/** end */
class Table {
  constructor(el, data) {
    this.el = el;
    this.draw = new Draw(el, data.viewWidth(), data.viewHeight());
    this.data = data;

    const that = this;

    // Whenever formulaParser.parser encounters a cell reference, it will
    // execute this callback to query the true value of that cell reference.
    // If the referenced cell contains a formula, we need to use formulaParser
    // to determine its value---which will then trigger more callCellValue
    // events to computer the values of its cell references. This recursion
    // will continue until the original formula is fully resolved.
    const getFormulaParserCellValue = function (cellCoord) {
      let cellText = that.data.getCellTextOrDefault(cellCoord.row.index, cellCoord.column.index);

      // If cell contains a formula, return the result of the formula rather
      // than the formula text itself
      if (cellText && cellText.length > 0 && cellText[0] === '=') {
        const parsedResult = formulaParser.parse(cellText.slice(1));

        // If there's an error, return the error instead of the result
        return (parsedResult.error) ?
          parsedResult.error :
          parsedResult.result;
      }

      // The cell doesn't contain a formula, so return its contents as a value.
      // If the string is a number, return as a number;
      // otherwise, return as a string.
      return Number(cellText) || cellText;
    }

    formulaParser.on('callCellValue', function (cellCoord, done) {
      const cellValue = getFormulaParserCellValue(cellCoord);
      done(cellValue);
    });

    formulaParser.on('callRangeValue', function (startCellCoord, endCellCoord, done) {
      let fragment = [];

      for (let row = startCellCoord.row.index; row <= endCellCoord.row.index; row++) {
        let colFragment = [];

        for (let col = startCellCoord.column.index; col <= endCellCoord.column.index; col++) {
          // Copy the parts of the structure of a Parser cell coordinate used
          // by getFormulaParserCellValue
          const constructedCellCoord = {
            row: { index: row },
            column: { index: col }
          };
          const cellValue = getFormulaParserCellValue(constructedCellCoord);

          colFragment.push(cellValue);
        }
        fragment.push(colFragment);
      }

      done(fragment);
    })
  }

  resetData(data) {
    this.data = data;
    this.render();
  }

  render() {
    // resize canvas
    const { data } = this;
    const { rows, cols } = data;
    // fixed width of header
    const fw = cols.indexWidth;
    // fixed height of header
    const fh = rows.height;

    this.draw.resize(data.viewWidth(), data.viewHeight());
    this.clear();

    const viewRange = data.viewRange();
    // renderAll.call(this, viewRange, data.scroll);
    const tx = data.freezeTotalWidth();
    const ty = data.freezeTotalHeight();
    const { x, y } = data.scroll;
    // 1
    renderContentGrid.call(this, viewRange, fw, fh, tx, ty);
    renderContent.call(this, viewRange, fw, fh, -x, -y);
    renderFixedHeaders.call(this, 'all', viewRange, fw, fh, tx, ty);
    renderFixedLeftTopCell.call(this, fw, fh);
    const [fri, fci] = data.freeze;
    if (fri > 0 || fci > 0) {
      // 2
      if (fri > 0) {
        const vr = viewRange.clone();
        vr.sri = 0;
        vr.eri = fri - 1;
        vr.h = ty;
        renderContentGrid.call(this, vr, fw, fh, tx, 0);
        renderContent.call(this, vr, fw, fh, -x, 0);
        renderFixedHeaders.call(this, 'top', vr, fw, fh, tx, 0);
      }
      // 3
      if (fci > 0) {
        const vr = viewRange.clone();
        vr.sci = 0;
        vr.eci = fci - 1;
        vr.w = tx;
        renderContentGrid.call(this, vr, fw, fh, 0, ty);
        renderFixedHeaders.call(this, 'left', vr, fw, fh, 0, ty);
        renderContent.call(this, vr, fw, fh, 0, -y);
      }
      // 4
      const freezeViewRange = data.freezeViewRange();
      renderContentGrid.call(this, freezeViewRange, fw, fh, 0, 0);
      renderFixedHeaders.call(this, 'all', freezeViewRange, fw, fh, 0, 0);
      renderContent.call(this, freezeViewRange, fw, fh, 0, 0);
      // 5
      renderFreezeHighlightLine.call(this, fw, fh, tx, ty);
    }
  }

  clear() {
    this.draw.clear();
  }
}

export default Table;
/* eslint-enable */
