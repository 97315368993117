import { useEffect, useRef, useState } from "react";
import TemplateEditor from "./templateEditor";
import { Tag } from "antd";
import "./templates.css";
import PreviewTemplate from "./preview";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import { debounce } from "lodash";
import EmailForm from "./emailForm";

const TemplatesComp = ({ sheetFromReset }) => {

    const [selectedHeader, setSetSelectedHeader] = useState('');
    const [isPreview, setIsPreview] = useState(false);
    let sheet = sheetFromReset;
    const selectedData = sheet?.data.templateSheet;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isEmailTemplate, setIsEmailTempalte] = useState(false);
    const [toText, setToText] = useState('');
    const [bccText, setBccText] = useState('');
    const [ccText, setCcText] = useState('');
    const [subjectText, setSubjectText] = useState('');
    const [activeField, setActiveField] = useState('editor');

    useEffect(() => {
        showEditorDataOnReset();
        setIsEmailTempalte(false);
        setToText('');
        setBccText('');
        setCcText('');
        setSubjectText('');
    }, [sheet.data]);

    const showEditorDataOnReset = () => {
        const initialContent = sheet.data.templateSheet.editorContent || '';
        if (initialContent != '') {
            try {
                const contentState = ContentState.createFromBlockArray(
                    convertFromHTML(initialContent)
                );
                if (contentState) {
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);
                } else {
                    console.error('okkkInvalid contentState returned by convertFromHTML');
                }
            } catch (error) {
                console.error('okkkkError during convertFromHTML:', error);
            }
        }
        else {
            setEditorState(EditorState.createEmpty());
        }
    };

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        const size = sheet.el.el.getBoundingClientRect();
        setScreenSize({
            width: size.width,
            height: size.height,
        });
    };

    useEffect(() => {
        showEditorDataOnReset();
        setToText('');
        setBccText('');
        setCcText('');
        setSubjectText('');
        const size = sheet?.el?.el.getBoundingClientRect();
        size && setScreenSize({
            width: size.width,
            height: size.height,
            top: '0px'
        });
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            sheetFromReset.data = sheet.data;
            debouncedSaveToDatabase.cancel();
            setIsEmailTempalte(false);
        };

    }, []);

    const handlePreview = () => {
        setIsPreview(true);
    }

    const handleEditorStateChange = (content) => {
        setEditorState(content);
        setActiveField('editor')
        const contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            debouncedSaveToDatabase.cancel();
            sheet.data.templateSheet.editorContent = '';
            let payload = sheet.data.getData();
            payload.templateSheet = sheet.data.templateSheet;
            sheet.trigger('change', payload);
            return;
        }
        const html = stateToHTML(contentState);
        sheet.data.templateSheet.editorContent = html;
        debouncedSaveToDatabase(html);
    };

    const debouncedSaveToDatabase = useRef(debounce(() => {
        let payload = sheet.data.getData();
        payload.templateSheet = sheet.data.templateSheet;
        if (debouncedSaveToDatabase.cancelToken) {
            debouncedSaveToDatabase.cancelToken.cancel("okkkkkOperation canceled by the user.");
        }
        else {
            sheet.trigger('change', payload);
        }
    }, 400)).current;

    const handelSendEmail = () => {
        setIsEmailTempalte(false);
    }

    const handleHeaderClick = (header) => {
        setSetSelectedHeader(header);

        let updatedText = '';
        switch (activeField) {
            case 'to':
                updatedText = `{{${header}}}`;
                setToText((prevToText) => prevToText + ' ' + updatedText);
                break;
            case 'bcc':
                updatedText = `{{${header}}}`;
                setBccText((prevBccText) => prevBccText + ' ' + updatedText);
                break;
            case 'cc':
                updatedText = `{{${header}}}`;
                setCcText((prevCcText) => prevCcText + ' ' + updatedText);
                break;
            case 'subject':
                updatedText = `{{${header}}}`;
                setSubjectText((prevSubjectText) => prevSubjectText + ' ' + updatedText);
                break;
            default:
                break;
        }
    };
    const handelIsEmailTemplate = () => {
        // sheet.data.templateSheet.isEmailTemplate = !isEmailTemplate;
        setIsEmailTempalte(prevState => !prevState);
    }
    return (
        <>
            <div
                id="my-template-container"
                style={{
                    width: screenSize.width,
                    height: screenSize.height,
                    top: screenSize.top,
                    overflow:'scroll'
                }}
                className="main-template-container"
            >
                <div className="header-conatiner">
                    <div className="all-tag-cotainer">
                        <div className="tags-container" style={{ margin: '8px 0px 0px 8px' }}>
                            {selectedData && selectedData.headers && selectedData.headers.length > 0 ? (
                                selectedData.headers.map((header, index) => (
                                    header !== '' && (
                                        <Tag
                                            key={index}
                                            onClick={() => handleHeaderClick(header)}
                                            className='header-tag-style'
                                        >
                                            {header}
                                        </Tag>
                                    )
                                ))
                            ) : (
                                <></>
                                // <span>No data</span>
                            )}
                        </div>
                        {/* <div className="tags-container" style={{ marginLeft: '8px' }}>
                            {selectedData && selectedData.emails && selectedData.emails.length > 0 ? (
                                selectedData.emails.map((emailArray) => (
                                    emailArray.slice(1).map((email, index) => {

                                        return email !== '' && (
                                            <Tag
                                                key={index}
                                                onClick={() => handleHeaderClick(email)}
                                                className='email-tag-style'
                                            >
                                                {email}
                                            </Tag>
                                        );
                                    })
                                ))
                            ) : (
                                <></>
                                // <span>No data</span>
                            )}
                        </div> */}
                    </div>
                    <div className="header-btn-container">
                        <button
                            className="btnn-style preview-btn-style"
                            onClick={handlePreview}
                        >
                            Preview
                        </button>
                        <button
                            className="btnn-style"
                            onClick={handelIsEmailTemplate}
                        >
                            is Email
                        </button>
                        {/* {isEmailTemplate && (
                            <button
                                className="btnn-style"
                                onClick={handelSendEmail}
                            >
                                Send
                            </button>
                        )} */}
                    </div>
                </div>
                {isEmailTemplate && (
                    <EmailForm
                        toText={toText}
                        bccText={bccText}
                        ccText={ccText}
                        subjectText={subjectText}
                        setToText={setToText}
                        setBccText={setBccText}
                        setCcText={setCcText}
                        setSubjectText={setSubjectText}
                        setActiveField={setActiveField}
                        isPreview={isPreview}
                    />
                )}
                <div className="editor-container-style">
                    <TemplateEditor
                        editorState={editorState}
                        setEditorState={setEditorState}
                        selectedHeader={selectedHeader}
                        setSetSelectedHeader={setSetSelectedHeader}
                        handleEditorStateChange={handleEditorStateChange}
                        activeField={activeField}
                    />
                </div>
            </div >
            {isPreview &&
                <PreviewTemplate
                    sheet={sheet}
                    screenSize={screenSize}
                    isPreview={isPreview}
                    setIsPreview={setIsPreview}
                    editorState={editorState}
                    isEmailTemplate={isEmailTemplate}
                    toText={toText}
                    bccText={bccText}
                    ccText={ccText}
                    subjectText={subjectText}
                    setToText={setToText}
                    setBccText={setBccText}
                    setCcText={setCcText}
                    setSubjectText={setSubjectText}
                    setActiveField={setActiveField}
                />
            }
        </>
    );

};

export default TemplatesComp;
