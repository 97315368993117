import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Radio } from "antd";
import "./form.css";
import { useSpreadSheet } from "../../context/spreadsheet-context";
import { expr2cellRangeArgs } from "../../x-data-spreadsheet/src/core/alphabet";
import { useParams } from "react-router-dom";
import { updateSheet } from "../../utils/api";
import { useDocument } from "../../Pages/SpreadsheetContainer/documentInfoContex";

const { confirm } = Modal;
const CreateForm = ({
  isVisible,
  setIsVisible,
  count,
  setCount,
  setIsEditPivotTable,
}) => {
  const { id } = useParams();
  const { spreadsheetInstanceRef } = useSpreadSheet();
  const { sheets } = useDocument();
  const [insertOption, setInsertOption] = useState("new");
  const [dataRange, setDataRange] = useState("");
  const [dataRangeError, setDataRangeError] = useState(null);
  const [existingDataRange, setExistingDataRange] = useState('');

  const getRangeText = (selector) => {
    if (typeof selector === "string") {
      return selector;
    } else if (typeof selector === "object" && selector !== null) {
      const { sri, sci, eri, eci } = selector;
      return `${String.fromCharCode(65 + sci)}${sri + 1}:${String.fromCharCode(
        65 + eci
      )}${eri + 1}`;
    } else {
      throw new Error("Invalid selector type");
    }
  };

  useEffect(() => {
    setDataRange(spreadsheetInstanceRef?.current?.sheet?.data?.selector?.range);
  }, []);

  const handleDataRangeChange = (e) => {
    const { value } = e.target;
    setDataRange(value);
    const cellRangeArgs = expr2cellRangeArgs(value);
    if (!cellRangeArgs) {
      setDataRangeError("please enter a valid range");
    } else {
      setDataRangeError(null);
    }
  };

  const createPivotTable = () => {
    const { sheet } = spreadsheetInstanceRef.current;
    const cellRangeArgs = expr2cellRangeArgs(getRangeText(dataRange));
    if (!cellRangeArgs) {
      return;
    }
    const [sri, sci, eri, eci] = cellRangeArgs;
    const data = [];
    const headers = [];
    for (let ci = sci; ci <= eci; ci += 1) {
      const column = [];
      for (let ri = sri; ri <= eri; ri += 1) {
        const cell = sheet.data.getCell(ri, ci);
        const cellData = cell && typeof cell.text === 'string' ? cell.text.trim() : null;
        if (ri === sri) {
          headers.push(cellData || "");
          column.push(cellData || "");
        } else {
          column.push(cellData || "");
        }
      }
      if (column.length > 0) {
        data.push(column);
      }
    }
    const pivotData = {
      headers: headers,
      data: data,
      isEdit: true,
      selectedRowData: [],
      selectedColData: [],
      selectedValueData: [],
      isExistingSheet: false,
      existingSheetRange: null
    };
    
    if (headers.length === 0 || (headers.length > 0 && headers?.[0] === '')) {
      setDataRangeError("Please enter a range with data.");
      return;
    }
    
    if (insertOption === "new") {
      if (dataRange == '') {
        return;
      }
      let sheetName = findSheetName();
      setCount((prevCount) => prevCount + 1);
      const newSheetData = spreadsheetInstanceRef.current.addSheet(
        sheetName,
        true,
        true
      );
      setIsEditPivotTable(true);
      newSheetData.pivotData = pivotData;
      sheet.resetData(newSheetData);
      let payload = newSheetData.getData();
      payload.pivotData = pivotData;
      updateSheet(id, payload);
      setIsVisible(false);
    } else {
      if (existingDataRange == '') {
        return;
      }
      if (Object.values(sheet.data.pivotData).length > 1) {
        showConfirmationModal(pivotData);
      }
      else {
        addPivotTableToExistingSheet(pivotData);
      }
    }
  };

  const addPivotTableToExistingSheet = (pivotData) => {
    const { sheet } = spreadsheetInstanceRef.current;
    const cellRangeArgs = expr2cellRangeArgs(getRangeText(existingDataRange.toUpperCase()));
    setIsEditPivotTable(true);
    let payload =
    {
      ...sheet.data.getData(),
      pivotData: {
        ...pivotData,
        existingSheetRange: cellRangeArgs,
        isExistingSheet: true
      }
    };
    sheet.data.pivotData = payload.pivotData;
    updateSheet(id, payload);
    clearSelectedRange(sheet, cellRangeArgs);
    setIsVisible(false);
  }

  const findSheetName = () => {

    const sheetNames = sheets.map((sheet) => sheet.name);
    let maxCount = 0;
    const pivotTableNames = sheetNames && Array.isArray(sheetNames) && sheetNames.filter(name => name && name.includes("Pivot Table"));
    const counts = pivotTableNames.map(name => {
      const match = name.match(/Pivot Table (\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    });
    maxCount = counts && Math.max(...counts);
    let name = maxCount >= 0 ? `Pivot Table ${maxCount + count}` : `Pivot Table ${count}`
    return name;
  }

  const clearSelectedRange = (sheet, existingSheetRange) => {
    let pivotData = sheet.data.pivotData;
    let loopLength = pivotData.data.length;
    let rowCount = pivotData.data.reduce((r, s) => r.length > s.length ? r.length : s.length, 0) + existingSheetRange[0];
    for (let i = 0; i <= loopLength + 1; i++) {
      let rowCi = i + existingSheetRange[1];
      let colRi = i + existingSheetRange[0];
      for (let j = 0; j <= rowCount; j++) {
        let rowRi = j + existingSheetRange[0];
        let colCi = j + existingSheetRange[1];
        sheet.data.rows.deleteCell(rowRi, rowCi, 'text');
        sheet.data.rows.deleteCell(rowRi, rowCi, 'format');
        sheet.data.rows.deleteCell(colRi, colCi, 'text');
        sheet.data.rows.deleteCell(colRi, colCi, 'format');
      }
    }
    sheet.table.render();
  }

  const handelExistingSheetRange = (e) => {
    const { value } = e.target;
    setExistingDataRange(value);
  }

  const showConfirmationModal = (pivotData) => {
    confirm({
      title: "Existing pivot tables turn read-only after multiple tables.",
      content: 'This action cannot be undone.',
      onOk() {
        addPivotTableToExistingSheet(pivotData);
      },
      onCancel() {
      },
      zIndex: 9999,
      maskClosable: true,
    });
  };

  return (
    <>
      <div style={{ width: "150px" }}>
        <Modal
          className="my-modal"
          title="Create Pivot Table"
          visible={isVisible}
          onCancel={() => setIsVisible(false)}
          maskClosable={false}
          mask={false}
          width={280}
          footer={[
            <Button key="cancel" type="" onClick={() => setIsVisible(false)}>
              Cancel
            </Button>,
            <Button key="create" type="primary" onClick={createPivotTable}>
              Create
            </Button>,
          ]}
        >
          <div>
            <Form layout="vertical">
              <Form.Item
                label="Data range"
                validateStatus={dataRangeError ? "error" : ""}
                help={dataRangeError}
              >
                <Input
                  placeholder="e.g. A:B"
                  value={dataRange}
                  onChange={handleDataRangeChange}
                />
              </Form.Item>

              <Form.Item label="Insert to">
                <Radio.Group
                  value={insertOption}
                  onChange={(e) => setInsertOption(e.target.value)}
                >
                  <Radio value="new">New sheet</Radio>
                  <br />
                  <Radio value="existing">Existing sheet</Radio>
                </Radio.Group>
              </Form.Item>
              {insertOption === 'existing' && (
                <Form.Item
                  validateStatus={existingDataRange != '' || expr2cellRangeArgs(existingDataRange) ? "success" : "error"}
                  help={expr2cellRangeArgs(existingDataRange) ? "" : "Please enter a valid range"}
                >
                  <Input
                    placeholder="e.g. A2"
                    value={existingDataRange}
                    onChange={handelExistingSheetRange}
                  />
                </Form.Item>
              )}
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CreateForm;
