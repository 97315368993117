import React ,{useState,useEffect}from "react";
import Mouse from "./mouse";
import useMousePosition from '../hooks/useMousePosition'
import socket, {
  addTotalClients,
  connectedClients,
  emitMousePositions,
  removeTotalClients,
  renderMouse,
  emitRoomID
} from '../socket'
import {debounce} from "lodash"
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { getWorkWiseAuthToken } from "../utils/helper";
import { useDocument } from "./SpreadsheetContainer/documentInfoContex";





const MouseContainer = () => {
  const  {id } = useParams();
  const [mousePositions, setMousePositions] = useState({
    serverX: null,
    serverY: null,
  });
  const [clientSocketID, setClientSocketID] = useState(null);
  const [connectedSocketID, setConnectedSocketID] = useState([]); // Define and initialize setConnectedSocketID
  const [numberOfConnectedClients, setNumberOfConnectedClients] = useState(0);
  const [user, setUser] = useState({});
  const { x, y, userName } = useMousePosition();
  const {userInfo}=useDocument()

  const debouncedMousePosition = useRef(debounce((x, y, userInfo) => {
    // emitRoomID(id)
    setClientSocketID(socket.id);
    emitMousePositions({ id, x, y, userName: userInfo.name });
  }, 250)).current;

  useEffect(() => {
    debouncedMousePosition(x, y, userInfo);
  }, [x, y]);

  useEffect(() => {
    renderMouse(setConnectedSocketID);
  }, []);

  return (
    <>
      {connectedSocketID.map((user, index) => {
        return (
          clientSocketID !== user.clientSocketID &&
          (
            <Mouse serverX={user.x} serverY={user.y} index={index} name={user.userName} />
          )
        );
      })}
    </>
  )
};

export default MouseContainer;
