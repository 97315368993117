import { SUPPORTED_FORMULAS } from 'hot-formula-parser';
import Icon from './icon';
import { Element, h } from './element';
import { cssPrefix } from '../config';
import { bindClickoutside, unbindClickoutside } from './event';
import { getFormulaDescription } from '../../../utils/formulaDescription';

class Dropdown extends Element {
  constructor(title, width, showArrow, placement, ...children) {
    super('div', `${cssPrefix}-dropdown ${placement}`);
    this.title = title;
    this.change = () => {};
    this.headerClick = () => {};
    if (typeof title === 'string') {
      this.title = h('div', `${cssPrefix}-dropdown-title`).child(title);
    } else if (showArrow) {
      this.title.addClass('arrow-left');
    }
    this.contentEl = h('div', `${cssPrefix}-dropdown-content-formula`)
      .css('width', width)
      .hide();

    this.setContentChildren(...children);

    this.headerEl = h('div', `${cssPrefix}-dropdown-header`);
    this.headerEl.on('click', () => {
      if (this.contentEl.css('display') !== 'block') {
        this.show();
      } else {
        this.hide();
      }
    }).children(
      this.title,
      showArrow ? h('div', `${cssPrefix}-icon arrow-right`).child(
        h('div', `${cssPrefix}-icon-img arrow-down`),
      ) : '',
    );
    this.children(this.headerEl, this.contentEl);
  }

  setContentChildren(...children) {
    this.contentEl.html('');
    if (children.length > 0) {
      this.contentEl.children(...children);
    }
  }

  setTitle(title) {
    this.title.html(title);
    this.hide();
  }

  show() {
    const { contentEl } = this;
    contentEl.show();
    this.parent().active();
    bindClickoutside(this.parent(), () => {
      this.hide();
    });
  }

  hide() {
    this.parent().active(false);
    this.contentEl.hide();
    unbindClickoutside(this.parent());
  }
}


export default class DropdownFormula extends Dropdown {
  constructor() {
    const nformulas = SUPPORTED_FORMULAS.map(it => h('div', `${cssPrefix}-item`)
      .on('click', () => {
        this.hide();
        this.change(it);
        this.showFormulaTooltip(it);
      })
      .child(it));
    super(new Icon('formula'), '180px', true, 'bottom-left limit-height', ...nformulas);
  }

  showFormulaTooltip(it) {

    let existingTooltip = document.querySelector('.formula-tooltip');
    let cellElement = document.querySelector('.x-spreadsheet-editor-area');
    const tooltip = document.createElement('div');
    tooltip.classList.add('formula-tooltip');
  
    const tooltipContent = document.createElement('span');
    tooltipContent.classList.add('formula-tooltip-content');
    let description = getFormulaDescription(it.toUpperCase());
    tooltipContent.innerHTML = description;
    tooltip.appendChild(tooltipContent);
    
    if (cellElement && !existingTooltip && description) {
      cellElement.appendChild(tooltip);
    }
    else if (existingTooltip && description) {
      existingTooltip.innerHTML = '';
      existingTooltip.appendChild(tooltipContent);
    }
    else if(existingTooltip && !description){
      existingTooltip.remove();
    }
  }
}
