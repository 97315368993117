import React from 'react';
import completeIcon from '../../assets/complete.png';
import { message, Modal } from 'antd';
import { useDocument } from '../../Pages/SpreadsheetContainer/documentInfoContex';
import { workwiseServices } from '../../Workw-Api/api';

const { confirm } = Modal;

const CompleteDocument = () => {
    const { documentInfo, setIsReadOnly, } = useDocument();

    const handleClick = async () => {
        try {
            await workwiseServices.completeDocument(documentInfo.referenceId);
            message.success('Document completed successfully.');
            setIsReadOnly(true);
        } catch (error) {
            message.error('Failed to complete the document. Please try again.');
        }
    };

    const showConfirmationModal = (e) => {
        e.preventDefault();
        confirm({
            title: 'Are you sure you want to complete this document?',
            content: 'This action cannot be undone.',
            onOk() {
                handleClick();
            },
            onCancel() {
            },
            zIndex: 9999,
            maskClosable: true,
        });
    };

    return (
        <div
            style={{
                marginLeft: "8px",
                cursor: 'pointer'
            }}
            title='Complete document'
            onClick={(e) => showConfirmationModal(e)}
        >
            <img src={completeIcon} width={21} alt="Complete Document" />
        </div>
    );
};

export default CompleteDocument;