import React from "react";
// import { Dropdown } from "antd";
import { Menu, Dropdown } from "antd";
import classes from "./navTabs.module.css";
import SubMenu from "antd/lib/menu/SubMenu";
import { MenuItem } from "rc-menu";

export const ViewTab = () => {
  const isMobile = window.screen.width < 769;

    // const passFunc = () => console.log('CLICKED');
    const elem = document.documentElement;
    const forZoom = (z) => {
      // const zoomSheet = document.getElementsByClassName('x-spreadsheet-table')[0];
      elem.style.zoom = z;
        // console.log(zoomSheet);
    };

    const forFullScreen = () => {
      console.log(elem);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    };

    const menu = (      
    <Menu>
      <MenuItem key="sub20" onClick={ forFullScreen }>
        <div>Full screen</div>
      </MenuItem>
      <SubMenu key="sub21" title="Zoom">
      <MenuItem key="sub21.1" onClick={() => forZoom("100%")}>
        <div>100%</div>
      </MenuItem>
      <MenuItem key="sub21.2" onClick={() => forZoom("125%")}>
        <div>125%</div>
      </MenuItem>
      <MenuItem key="sub21.3" onClick={() => forZoom("150%")}>
        <div>150%</div>
      </MenuItem>
      <MenuItem key="sub21.4" onClick={() => forZoom("175%")}>
        <div>175%</div>
      </MenuItem>
      </SubMenu>
    </Menu>
    );
    return (
    <Dropdown overlay={menu} trigger={["click"]} arrow>
        <div style={{fontSize:isMobile?'11px':'14px'}} className={classes.navTabButton} onClick={(e) => e.preventDefault()}>
            View
        </div>
    </Dropdown>
    );
};