import React, { useEffect } from 'react';
import { EditorState, ContentState, Modifier, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './templateEditor.css'

const TemplateEditor = ({ selectedHeader, setSetSelectedHeader, editorState, setEditorState, handleEditorStateChange, activeField }) => {
    useEffect(() => {
        if (selectedHeader !== '' && activeField == 'editor') {
            const currentContent = editorState.getCurrentContent();
            const selection = editorState.getSelection();

            const newDataContentState = ContentState.createFromBlockArray(
                convertFromHTML(`{{${selectedHeader}}}`)
            );

            const updatedContentState = Modifier.replaceWithFragment(
                currentContent,
                selection,
                newDataContentState.getBlockMap()
            );
            const newEditorState = EditorState.push(
                editorState,
                updatedContentState,
                'insert-fragment'
            );
            setEditorState(newEditorState);
        }
        return () => {
            setSetSelectedHeader('');
        };
    }, [selectedHeader]);

    function uploadImageCallBack(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                resolve({ data: { link: imageUrl } });
            };
            reader.onerror = () => {
                reject(new Error('Image upload failed.'));
            };
            reader.readAsDataURL(file);
        });
    }

    return (
        <div>
            <div>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={(content) => handleEditorStateChange(content)}
                    placeholder='Write here...'
                    toolbar={{
                        image: {
                            uploadCallback: uploadImageCallBack,
                            alt: { present: true, mandatory: false },
                            previewImage: true,
                            "previewImage": true,
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default TemplateEditor;
