import { io } from "socket.io-client";
import { UserSelector } from "./UserSelector/UserSelector";
import { UserSelectorRegistry } from "./UserSelector/UserSelectorRegistry";

const serverURL = "https://grid.workw.com/";
// const serverURL = "http://localhost:8080";
// const serverURL = "http://192.168.18.81:8080";
const socket = io(serverURL);
const registryInstance = new UserSelectorRegistry()

//Event emitters

export const emitSingularCellSelection = (data) => {
  socket.emit("cell-selected", data);
};

export const emitRoomID = (id) => {
  socket.emit("room-id", id);
};

export const emitLiveComments = (data) => {
  socket.emit("comments", data);
  // console.log(data);
};

 export const emitMultipleCellSelection = (data) => {
   socket.emit("cells-selected", data);
 };

export const emitEditedCellData = (data) => {
  socket.emit("cell-edited", data);
};

export const emitLoadImport = (data) => {
  socket.emit("load-data", data);
  console.log(data);
};

// export const emitCellStyle = (data) => {
//   socket.emit("cell-style", data);
// };

export const emitAddSheet = (data) => {
  socket.emit("add-sheet", data);
  console.log(data);
};

export const emitOnChanged = (id, data) => {
  socket.emit("change", { id: id, data: data });
  // console.log(data);
};
//Emits mouse coordinates to the server.
export const emitMousePositions = (obj) => {
  if(obj.userName){
  socket.emit("mouse-positions", obj)};
};

// Event Listeners

export const listenerSingularCellSelection = (
  spreadsheetInstanceRef,msg
) => {
  socket.on("cell-selected", ({id,data}) => {
    // console.log('Listener Selected Cell Called',msg)
    const ref = spreadsheetInstanceRef;
    const { selData } = data;
    const {height,width,top,left} = selData.pos;
    if (selData.data.name === ref.current.data.name) {
      registryInstance.updateUserSelectorPosition(id,height,width,top,left);
    } else {
      registryInstance?.hideUserSelector(id);
    }
    // const { selector } = ref.current.sheet;
    // selector.set(columnIndex, rowIndex);
    // ref.current.reRender();
    // console.log(sheetData);
  });
};

 export const listenerMultipleCellSelection = (spreadsheetInstanceRef) => {
  socket.on("cells-selected", ({id,data}) => {
    // const ref = spreadsheetInstanceRef;
    const { selsData } = data;
    const { height, width, top, left } = selsData.pos;
    registryInstance.updateUserSelectorPosition(id,height,width,top,left);
    // if (selsData.data.name === ref.current.data.name) {
    //   registryInstance.updateUserSelectorPosition(id,height,width,top,left);
    // } else {
    //   registryInstance.hideUserSelector(id);
    // }
    // const { endRowIndex, endColumnIndex } = data;
    // const { selector } = ref.current.sheet;
    // selector.setEnd(endRowIndex, endColumnIndex);
    // ref.current.reRender();
  });
};

export const listenerEditedCellData = (spreadsheetInstanceRef) => {
  socket.on("cell-edited", (data) => {
    // if (document.activeElement.className === 'textarea') {
    //   const { dataText, rowIndex, columnIndex } = data;
    //   const ref = spreadsheetInstanceRef.current;
    //   if (dataText) {
    //     ref.cellText(rowIndex, columnIndex, dataText).reRender();
    //   }
    // }

    const { dataText, rowIndex, columnIndex, sheetIndex } = data;
    const ref = spreadsheetInstanceRef.current;
    if (dataText) {
      if ((ref.datas.length === 1) && (sheetIndex === 0)) {
        ref.cellText(rowIndex, columnIndex, dataText, sheetIndex).reRender();
      } else if ((ref.datas.length > 1) && (sheetIndex <= ref.datas.length)) {
        ref.cellText(rowIndex, columnIndex, dataText, sheetIndex).reRender();
      }
    }
  });
};

// export const listenerCellStyle = (spreadsheetInstanceRef) => {
//   socket.on("cell-style", (data) => {
//     const { rowIndex, columnIndex, cStyle } = data;
//     const ref = spreadsheetInstanceRef.current;
//     const dataProxy = ref.data;
//     const { rows } = dataProxy;

//     const cell = rows.getCell(rowIndex, columnIndex);
//     if (cell && cStyle !== undefined && cell.style !== cStyle) {
//       // console.log(cStyle, "listenStyle");
//       cell.style = dataProxy.addStyle(cStyle);
//       ref.reRender();
//     }
//   });
// };

export const listenerAddSheet = (spreadsheetInstanceRef) => {
  socket.on("add-sheet", (data) => {
    const { datasLength } = data;
    const ref = spreadsheetInstanceRef.current;
    // console.log("mine", ref.datas.length);
    // console.log("other", datasLength);
    if (datasLength) {
      const d = ref.addSheet();
      ref.sheet.resetData(d);
    }
  });
};

export const listenerOnChanged = (spreadsheetInstanceRef) => {
  socket.on("change", (data) => {
    const ref = spreadsheetInstanceRef.current;
    const refSheet = ref.sheet;
    const initialIndex = refSheet.selector.indexes;
    if (!(document.activeElement.className === 'textarea')) {
      // spreadsheetInstanceRef.current.loadData(data);
      const [ri, ci] = initialIndex;
      refSheet.selector.set(ri, ci);
    } else {
      ref.dataQueue.enqueue(data);
    }
    // if (document.activeElement.className === 'textarea') {
      //   while (!(document.activeElement.className === 'textarea')) {
        //     break;
        //   }
        // } else {
          //   spreadsheetInstanceRef.current.loadData(data);
          //   const [ri, ci] = initialIndex;
    //   refSheet.selector.set(ri, ci);
    // }
  });
};

/* export const listenerUpdateCellData = (spreadsheetInstanceRef) => {
  socket.on("update-cell-data", (data) => {
    const { dataText, rowIndex, columnIndex } = data;
    console.log(data, "listenerUPDATE")
    spreadsheetInstanceRef.cellText(rowIndex, columnIndex, dataText).reRender();
  });
}; */

/* export const listenerOnChangeCellData = () => {
  socket.on('multiple-cell-selection', (data) => {
    console.log(data);
  })
}

export const emitCellIndexes = (data) => {
  socket.emit("cell-indexes",data);
}

export const emitCellStyle = (data) => {
  socket.emit("cell-styles",data);
}



*/
export const listenerLiveComments = (updateMessageList) => {
  socket.on("comments", (data) => {
    data.color = "#98b3e6";
    data.margin = "5px auto 5px 0px"; //this styling work should be done through id
    updateMessageList(data);
  });
};

export const listenerLoadImport = (spreadsheetInstanceRef) => {
  socket.on("load-data", (data) => {
    spreadsheetInstanceRef.current.loadData(data);
    // console.log(data);
  });
};

export const listenerClients = (spreadsheetInstanceRef) => {
  // Maintain a set or array to keep track of processed IDs
  const processedIds = new Set();

  socket.on("clients", (data) => {
    data.forEach((element) => {
      if (socket.id !== element && !processedIds.has(element)) {
        const selectorInstance = new UserSelector(
          spreadsheetInstanceRef
        );
        
        // Add the ID to the set of processed IDs
        processedIds.add(element);
        
        registryInstance.setUserInstanceToRegistry(element, selectorInstance);
      }
    });
  });
};

export const listenerClientArrOnDisconnect = () => {
  socket.on("client-arr",({id,clients}) => {
    // console.log("DISCONNETION WALA LOG");
    const clientArr = Array.from(clients);
    const result = clientArr.indexOf(id);
    if (result === -1) {
      registryInstance.deleteUserSelector(id)
    }
  })
}

export const addTotalClients = (setNumberOfConnectedClients) => {
  socket.on("total-clients", (totalClients) => {
    setNumberOfConnectedClients(totalClients);
  });
};

export const connectedClients = (setConnectedClients) => {
  socket.on("connected-clients-ids", (connectedClientArr) => {
    // console.log(connectedClientArr);
    setConnectedClients(connectedClientArr);
  });
};

export const removeTotalClients = (setNumberOfConnectedClients) => {
  socket.on("disonnected-client", (totalClients) => {
    setNumberOfConnectedClients(totalClients);
  });
};
//Listener For Mouse Positions 
// const [connectedSocketID, setConnectedSocketID] = useState([]); // Define and initialize setConnectedSocketID

export const renderMouse = (setConnectedSocketID) => {
  socket.on("mouse-positions", (info) => {
    setConnectedSocketID(info);
     });
};
export const emitAddMemebers=(id)=>{
  // console.log('addMemberCalled',id); //Testing Purpose
  socket.emit('add-members',id)
}
export const listenAddMember=(callback)=>{
  socket.on('add-members',(data)=>{
    if(socket.id!==data.id){
    // console.log('Listener _ addMemberCalled'); //Testing Purpose
    callback()}
  })
}

export default socket;
