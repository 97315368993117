import React from "react";
import { GiArrowCursor } from "react-icons/gi";
// import Avatar from "../../assets/avatar.jpg";
import "./styles.css";
import useRandomColor from "../hooks/useRandomColor";

const Mouse = ({ serverX, serverY, index,name }) => {
  const color = useRandomColor();
  const scrrenWidth=window.screen.width;

  let userDetailsX = serverX-10;
  let userDetailsY = serverY-17;

  return (
    <>
      <GiArrowCursor
        style={{
          color: color,
          position: "absolute",
          display:serverX<scrrenWidth?"block":"none",
          left: serverX-25,
          top: serverY-15,
          zIndex:1000

        }}
      />
      <div
        style={{
          // backgroundColor:`#${color}`,
          display: "flex",
          display:serverX<scrrenWidth?"block":"none",
          alignItems: "center",
          justifyContent:"center",
          width: 'fit-content',
          height: "50px",
          position: "absolute",
          color: color,
          borderRadius: "3px",
          left: userDetailsX,
          top: userDetailsY,
          zIndex:1000,
          pointerEvents:'none'
        }}
      >
        {/* <img src={Avatar} className="user-img" alt="user" /> */}
        {name &&
        <h6 style={{ color: `#${color}` }} className="user-name">{name||`User 0${index + 1}`}</h6>}
      </div>
    </>
  );
};

export default Mouse;
