import { tf } from '../locale/locale';
import barChartIcon from '../../../assets/Chart-Icons/bar-chart-icon.png';
import lineChartIcon from '../../../assets/Chart-Icons/line-chart-icon.png';
import areaChartIcon from '../../../assets/Chart-Icons/area-chart-icon.png';
import scatterChartIcon from '../../../assets/Chart-Icons/scatter-chart-icon.png';

const chartTypes = [
    {
        key: 'bar',
        title: tf('chartTypes.bar'),
        // type: 'string',
        image: barChartIcon,
    },
    {
        key: 'line',
        title: tf('chartTypes.line'),
        // type: 'string',
        image: lineChartIcon,
    },
    {
        key: 'area',
        title: tf('chartTypes.area'),
        // type: 'number',
        image: areaChartIcon,
    },
    // {
    //     key: 'scatter',
    //     title: tf('chartTypes.scatter'),
    //     // type: 'number',
    //     image: scatterChartIcon,
    // },
];

const chartsm = {};
chartTypes.forEach((f) => {
    chartsm[f.key] = f;
});

export default {
};
export {
    chartsm,
    chartTypes,
};
