import React, { useState } from "react";
import { Route, Switch} from "react-router-dom";
import Header from "./components/Header/Header";
import "./index.css";
import { SpreadsheetContainer } from "./Pages/SpreadsheetContainer/SpreadsheetContainer";
import { SpreadsheetContainerRd } from "./Pages/SpreadsheetContainer/SpreadsheetContainerRd";
import { AuthenticateDocument } from "./utils/AuthenticateDocument";
import ResultComponent from "./components/ResultComponent/ResultComponent";
import { useDocument } from "./Pages/SpreadsheetContainer/documentInfoContex";
import OfflineStatus from "./components/Header/OfflineStatus/OfflineStatus";

function App() {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { isReadOnly } = useDocument();
  
  return (
    <div className="App">
      <Switch>
        <Route exact path="/milegrid/:id">
          {isAuthenticating ? (
            <AuthenticateDocument setIsLoading={setIsAuthenticating} />
          ) : isReadOnly ? (
            <>
              <Header fileDisplay={false} />
              <OfflineStatus/>
              <SpreadsheetContainerRd />
            </>
          ) : (
            <>
              <Header fileDisplay={true} />
              <OfflineStatus/>
              <SpreadsheetContainer
              />
            </>
          )}
        </Route>
        <Route exact path="/invalid-document">
          <ResultComponent title="Document Verification Failed , Invalid Document. " />
        </Route>
        <Route path="/unauthorized">
          <ResultComponent title="Security Verification Failed , Unauthorized Access. " />
        </Route>
        <Route path="/no-internet">
          <ResultComponent title="No Internet, Check your internet connection." />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
