import React, { useState, useEffect } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import "./templates.css";
import { Button, Modal, message } from 'antd';
import EmailForm from './emailForm';
import { workwiseServices } from '../../Workw-Api/api';
import { getWorkWiseAuthToken } from '../../utils/helper';

const PreviewTemplate = ({
  isPreview,
  setIsPreview,
  editorState,
  screenSize,
  sheet,
  isEmailTemplate,
  toText,
  bccText,
  ccText,
  subjectText,
  setToText,
  setBccText,
  setCcText,
  setSubjectText,
}) => {

  const [htmlContent, setHtmlContent] = useState('');
  const [clickCount, setClickCount] = useState(0);
  const [emailValue, setEmailValue] = useState('');
  const templateData = sheet.data.templateSheet.data;
  const [previewToText, setPreviewToText] = useState(toText);
  const [previewBccText, setPreviewBccText] = useState(bccText);
  const [previewCcText, setPreviewCcText] = useState(ccText);
  const [previewSubjectText, setPreviewSubjectText] = useState(subjectText);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isPreview) {
      replaceTemplateVariables();
    }
  }, [clickCount]);

  const replaceTemplateVariables = () => {
    const contentState = editorState.getCurrentContent();
    const newHtmlContent = stateToHTML(contentState);
    const contentWithReplacement = newHtmlContent.replace(/{{([^}]+)}}/g, (_, match) => {
      const header = match.trim();
      const headerItem = templateData.find((item) => item[0] === header);
      const headerIndex = headerItem && templateData.indexOf(headerItem);
      if (headerIndex !== -1) {
        if (templateData[headerIndex]) {
          const dataRow = templateData[headerIndex][clickCount];
          return dataRow;
        } else {
          console.error(`Header '${header}' not found in templateData`);
          return match;
        }
      } else {
        console.error(`Header '${header}' not found in the first row of templateData`);
        return match;
      }
    });
    setHtmlContent(contentWithReplacement);
    if (sheet.data.templateSheet.emails && sheet.data.templateSheet.emails.length >= 1) {
      let uniqueEmails = Array.from(new Set(sheet.data.templateSheet.emails.flat()));
      if (uniqueEmails && uniqueEmails.length > 0) {
        setEmailValue(uniqueEmails[clickCount]);
      }
    }
    replaceFormFields(toText, setPreviewToText, true);
    replaceFormFields(bccText, setPreviewBccText, true);
    replaceFormFields(ccText, setPreviewCcText, true);
    replaceFormFields(subjectText, setPreviewSubjectText);
  };

  const handlePaginationChange = (page) => {
    setClickCount(page - 1);
  };

  const handleNext = () => {
    setClickCount((prevCount) => (prevCount + 1) % templateData[0].length);
  };

  const handlePrevious = () => {
    setClickCount((prevCount) => (prevCount - 1 + templateData[0].length) % templateData[0].length);
  };

  const replaceFormFields = (formFieldText, setFormField, isSemiColon = false) => {
    let newFormFieldText = formFieldText.replace(/{{([^}]+)}}/g, (_, match) => {
      const header = match.trim();
      const headerItem = templateData.find((item) => item[0] === header);
      const headerIndex = headerItem && templateData.indexOf(headerItem);

      if (headerIndex !== -1) {
        if (templateData[headerIndex]) {
          let dataRow = templateData[headerIndex][clickCount];
          if (isSemiColon)
            dataRow += ';';

          return dataRow
        } else {
          console.error(`Header '${header}' not found in templateData`);
          return match;
        }
      } else {
        console.error(`Header '${header}' not found in the first row of templateData`);
        return match;
      }
    });

    setFormField(newFormFieldText);
  };

  const verifyConfiguration = async (userId) => {
    let response = await workwiseServices.getMailConfiguration(userId)
    if (!response.data?.data?.length)
      throw new Error("Your email account is not configured!")

    return response.data?.data?.[0];
  };

  const sendEmail = async (emailCong, userInfo) => {
    const formData = new FormData();

    const toFields = previewToText?.split(";")?.map((item, index) => {
      const data = {
        email: item?.trim(),
        name: item?.trim()?.split("@")?.[0]
      }
      if (!!data.email) {
        formData.append(`to[${index}].name`, data.name);
        formData.append(`to[${index}].address`, data.email);
      }

      return data
    }).filter(item => item?.email)

    const ccFields = previewCcText?.split(";")?.map((item, index) => {
      const data = {
        email: item?.trim(),
        name: item?.trim()?.split("@")?.[0]
      }
      if (!!data.email) {
        formData.append(`cc[${index}].name`, data.name);
        formData.append(`cc[${index}].address`, data.email);
      }

      return data
    }).filter(item => item?.email)

    const bccFields = previewBccText?.split(";")?.map((item, index) => {
      const data = {
        email: item?.trim(),
        name: item?.trim()?.split("@")?.[0]
      }
      if (!!data.email) {
        formData.append(`bcc[${index}].name`, data.name);
        formData.append(`bcc[${index}].address`, data.email);
      }

      return data
    }).filter(item => item?.email)

    const content = sheet.data.templateSheet?.editorContent

    formData.append(`from[${0}].name`, userInfo.name);
    formData.append(`from[${0}].address`, userInfo.email);
    formData.append(`subject`, previewSubjectText);
    formData.append(`content`, htmlContent);
    formData.append(`isRead`, false);
    formData.append(`hasAttachments`, false);
    formData.append(`date`, new Date().toISOString());

    let response = await workwiseServices.sendEmail(formData)
    if (!response.data?.data)
      throw new Error(response.data?.message)

    message.success("Email Sent Successfully!")
  };

  const handleSendEmail = async (page) => {
    setLoading(true)
    try {
      let userInfo = JSON.parse(Buffer.from(getWorkWiseAuthToken()?.split('.')[1], 'base64').toString());
      let emailCong = await verifyConfiguration(userInfo.userId)

      await sendEmail(emailCong, userInfo)

    } catch (error) {
      message.error(error.message)
    }
    setLoading(false)
  };


  useEffect(() => {
  }, [bccText]);

  useEffect(() => {
    setClickCount((prevCount) => (prevCount + 1) % templateData[0].length);
  }, []);

  return (
    <Modal
      title="Preview Template"
      visible={isPreview}
      onCancel={() => setIsPreview(false)}
      footer={null}
      zIndex={999}
      width={screenSize.width}
      bodyStyle={{ minHeight: "500px" }}
    >
      <div className='form-continer'>
        {isEmailTemplate && (
          <>
            <EmailForm
              toText={previewToText}
              bccText={previewBccText}
              ccText={previewCcText}
              subjectText={previewSubjectText}
              setToText={setPreviewToText}
              setBccText={setPreviewBccText}
              setCcText={setPreviewCcText}
              setSubjectText={setPreviewSubjectText}
              isPreview={isPreview}
            />
          </>
        )}
      </div>
      <div className='content-continer'
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{
          marginLeft: isEmailTemplate ? '8px' : '',
          overflowY: 'scroll',
          height: !isEmailTemplate ? '600px' : '400px',
          marginTop: !isEmailTemplate ? '20px' : "",
        }}
      >
      </div>
      <div className="bottombar-container">
        <div className='counter-container'>
          <button className="btnn-style" onClick={handlePrevious} disabled={clickCount === 0}>Previous</button>
          <div className='count-text-style'>
            <span className="current-page">{clickCount}</span>
            <span className="total-pages">/{templateData[0].length - 1}</span>
          </div>
          <button className="btnn-style" onClick={handleNext} disabled={clickCount === templateData[0].length - 1}>Next</button>
        </div>
        {isEmailTemplate && (
          <div className='send-container'>
            <Button key="create" type="primary" onClick={handleSendEmail}
              size='medium'
              style={{ backgroundColor: "#526bb1", borderRadius: "5px" }}
              loading={loading}
            >
              Send
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PreviewTemplate;
