import { Col, Form, Input, Row } from 'antd';
import React from 'react';

const EmailForm = ({
  toText,
  bccText,
  ccText,
  subjectText,
  setToText,
  setBccText,
  setCcText,
  setSubjectText,
  setActiveField,
  isPreview,
}) => {
  const handleActiveField = (field) => {
    if (!isPreview) {
      setActiveField(field);
    }
  };

  const handelToTextInput = (e) => {
    let text = e.target.value;
    setToText(text);
    handleActiveField('to');
  };

  const handelBccInput = (e) => {
    let text = e.target.value;
    setBccText(text);
    handleActiveField('bcc');
  };

  const handelCccInput = (e) => {
    let text = e.target.value;
    setCcText(text);
    handleActiveField('cc');
  };

  const handelSubjectInput = (e) => {
    let text = e.target.value;
    setSubjectText(text);
    handleActiveField('subject');
  };

  return (
    <Form className="form-container" id='my-email-template-form'>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="To">
            <Input
              id='to-Text-feild'
              placeholder="to"
              value={toText}
              onClick={() => handleActiveField('to')}
              onChange={handelToTextInput}
            //   disabled={isPreview}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Bcc">
            <Input
              id='bcc-Text-feild'
              placeholder="bcc"
              value={bccText}
              onClick={() => handleActiveField('bcc')}
              onChange={handelBccInput}
            //   disabled={isPreview}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Cc">
            <Input
              id='cc-Text-feild'
              placeholder="Cc"
              value={ccText}
              onClick={() => handleActiveField('cc')}
              onChange={handelCccInput}
            //   disabled={isPreview}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Subject">
            <Input
              id='subject-Text-feild'
              placeholder="Subject"
              value={subjectText}
              onClick={() => handleActiveField('subject')}
              onChange={handelSubjectInput}
            //   disabled={isPreview}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EmailForm;
