import React from 'react';
import Logo from '../Logo/Logo'
import SheetName from '../SheetName/SheetName';
import Navigations from '../Navigations/Navigations';
import MyHeader from "./Header.module.css";
import { useDocument } from '../../Pages/SpreadsheetContainer/documentInfoContex';
import { ShareModal } from '../ShareModal/ShareModal';
import { UserAvatar } from '../Avatar/UserAvatar';
import DocumentMembers from '../Members/members';
import Modes from './modes';
import savingIcon from '../../assets/SavingIcon.png';
import savedIcon from '../../assets/savedIcon.png';
import CompleteDocument from '../CompleteDoc/completeDoc';
import publicIcon from '../../assets/world.svg';
import privateIcon from '../../assets/lock.svg';


const Header = ({ fileDisplay }) => {
    const { documentInfo, isReadOnly, userInfo,savingData } = useDocument();
    return (

        <div id='myy-header' className={MyHeader.headerMain}>
            <Logo />
            <div className={MyHeader.headerCol} style={{ justifyContent: isReadOnly ? 'center' : 'flex-end' }}>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <SheetName alreadyAligned={fileDisplay} documentInfo={documentInfo} />
                    <div>
                        {documentInfo?.privacyId == 2 ? (
                            <><img src={privateIcon} alt='Private documnet' style={{ width: '12px' }} title='Private documnet' />
                            </>
                        )
                            : (<> <img src={publicIcon} alt='Public documnet' style={{ width: '15px' }} title='Public documnet' /></>)}
                    </div>
                    {
                        !isReadOnly && <CompleteDocument />
                    }
                    {
                        savingData.isSaving &&
                        <div className={MyHeader.SavingSaved}>
                            {savingData.isSaving === 'Saving...' ? (
                                <img src={savingIcon} alt='Saving Icon' style={{ width: '22px' }} />
                            ) : (
                                <img src={savedIcon} alt='Saved Icon' style={{ width: '24px' }} />
                            )}
                            <span>
                                {savingData.isSaving}
                            </span>
                            <span>
                                {savingData.lastSavedDateTime}
                                {savingData.isSaving === 'Saved' && `, ${savingData.fileSize}`}
                            </span>
                        </div>
                    }
                </div>
                {fileDisplay && <Navigations />}
            </div>
            <div style={{ marginRight: '8px' }}>


                <DocumentMembers />
            </div>
            <div className={MyHeader.titleBarButtons} style={{width:documentInfo?.createBy == userInfo.userId?'140px':'92px'}}>
                {documentInfo?.createBy == userInfo.userId && (
                    <>
                        <ShareModal />

                    </>
                )}
                {documentInfo&&
                    <Modes/>}
                <UserAvatar />
            </div>
        </div>
    )
};
export default Header;

