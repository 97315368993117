import React, { createContext, useContext, useState } from "react";
const DocumentContext = createContext();

export const DocumentInfoProvider = ({ children }) => {
  const [documentInfo, setDocumentInfo] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [userInfo,setUserInfo] = useState({});
  const [docID,setDocID]=useState(null);
  const [sheets,setSheets] = useState([]);
  const [savingData,setSavingData]=useState({
    isSaving: '',
    lastSavedDateTime: "",
    fileSize:0,
  });

  return (
    <DocumentContext.Provider 
    value={{ documentInfo, setDocumentInfo,setIsReadOnly,isReadOnly,userInfo,setUserInfo,setDocID,docID,sheets,setSheets,savingData,setSavingData}}>
      {children}
    </DocumentContext.Provider>
  );
};

export const useDocument = () => {
  return useContext(DocumentContext);
};
