import React from "react";
// import { Dropdown } from "antd";
import { Menu, Dropdown } from "antd";
import classes from "./navTabs.module.css";
import SubMenu from "antd/lib/menu/SubMenu";
import { MenuItem } from "rc-menu";
import { useSpreadSheet } from "../../context/spreadsheet-context";

export const EditTab = () => {
  const isMobile = window.screen.width < 769;

  const { spreadsheetInstanceRef } = useSpreadSheet();
  // const passFunc = () => console.log('CLICKED')
  // const forPass = () => {
  //   console.log('PASSED');
  // };

  const forUndo = () => {
    // console.log(spreadsheetInstanceRef.current.data.canUndo());
    const { sheet } = spreadsheetInstanceRef.current;
    sheet.data.undo();
    sheet.table.render();
  };

  const forRedo = () => {
    // console.log(spreadsheetInstanceRef.current.data.canUndo());
    const { sheet } = spreadsheetInstanceRef.current;
    sheet.data.redo();
    sheet.table.render();
  };

  const forCut = () => {
    // console.log(spreadsheetInstanceRef.current.data.canUndo());
    const { sheet } = spreadsheetInstanceRef.current;
    sheet.data.cut();
    sheet.table.render();
  };

  const forCopy = () => {
    // console.log(spreadsheetInstanceRef.current.data.canUndo());
    const { sheet } = spreadsheetInstanceRef.current;
    sheet.data.copy();
    sheet.table.render();
  };

  const forPaste = (type) => {
    // console.log(spreadsheetInstanceRef.current.data.canUndo());
    const { sheet } = spreadsheetInstanceRef.current;
    // all | text | format
    sheet.data.paste(type);
    sheet.table.render();
  };

  const menu = (
    <Menu>
      <MenuItem key="sub1" onClick= { forUndo }>
        <div>Undo</div>
      </MenuItem>
      <MenuItem key="sub2" onClick= { forRedo }>
        <div>Redo</div>
      </MenuItem>
      <div class="nav-tab-divider"></div>
      <MenuItem key="sub3" onClick= { forCut }>
        <div>Cut</div>
      </MenuItem>
      <MenuItem key="sub4" onClick= { forCopy }>
        <div>Copy</div>
      </MenuItem>
      <MenuItem key="sub5" onClick= {() => forPaste('all')}>
        <div>Paste</div>
      </MenuItem>
      <SubMenu key="sub6" title="Paste with">
      <MenuItem key="sub6.1" onClick= {() => forPaste('text')}>
        Values
      </MenuItem>
      <MenuItem key="sub6.2" onClick= {() => forPaste('format')}>
        Format           
      </MenuItem>
      </SubMenu>
      {/* <SubMenu key="sub1" title="ITEM 1??">
          <MenuItem key='sub1.1'>
              <div>ITEM1</div>
          </MenuItem>
        </SubMenu>
        <SubMenu key="sub2" title="ITEM 2??">
          <MenuItem key='sub2.1'>
              <div>ITEM1</div>
          </MenuItem>
        </SubMenu>
        <SubMenu key="sub3" title="ITEM 3??">
          <MenuItem key='sub3.1'>
              <div>ITEM1</div>
          </MenuItem>
        </SubMenu>
        <SubMenu key="sub4" title="ITEM 4??">
          <MenuItem key='sub4.1'>
              <div>ITEM1</div>
          </MenuItem>
        </SubMenu> */}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["click"]} arrow>
      <div style={{fontSize:isMobile?'11px':'14px'}} className={classes.navTabButton} onClick={(e) => e.preventDefault()}>
        Edit
      </div>
    </Dropdown>
  );
};
