import React, { useEffect } from "react";
import { getWorkWiseAuthToken } from './helper'
import { workwiseServices } from "../Workw-Api/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDocument } from "../Pages/SpreadsheetContainer/documentInfoContex";
import Loader from "../components/SkeletonLoader/Loader";

export const AuthenticateDocument = ({ setIsLoading }) => {

  const history = useHistory();
  const { id } = useParams();
  const { setDocumentInfo, setIsReadOnly,setUserInfo,setDocID } = useDocument();

  const handleAuhenticate = async () => {
    try {
      if(id){
        setDocID(id);
      }
      setIsLoading(true);
      const searchParams = new URLSearchParams(document.location.search)
      const isReadOnlyQuery = searchParams.get("readOnly")
      if (isReadOnlyQuery == "true") {
        setIsReadOnly(true);
      }
      if (!window.navigator.onLine) {
        // history.push('/no-internet');
        window.location.pathname="/no-internet"

        return;
      }
      else {
        if (!!getWorkWiseAuthToken()) {
          const response = await workwiseServices.getDocumentById(id);
          if (response?.status == 200 && response.data?.data?.rightType !== 0 && response.data?.data) {
            setIsLoading(false);
            setDocumentInfo(response.data.data);
            if (response.data.data.rightType == 1) {
              setIsReadOnly(true);
            }
          }
          if (response.data?.data?.rightType == 0) {
            // history.push("/unauthorized")
            window.location.pathname="/unauthorized"
          }
          // history.push("/unauthorized")
        }
        else {
          // history.push("/unauthorized")
          window.location.pathname="/unauthorized"

        }
      }
    } catch (error) {
      console.log({ error })
      if (error.response?.data?.responseCode === 1008) {
        // history.push("/invalid-document")
        window.location.pathname="/invalid-document"

      }
      else {
        // history.push("/unauthorized")
        window.location.pathname="/unauthorized"

      }
    }
  }
  useEffect(() => {
    handleAuhenticate();
    let info = JSON.parse(Buffer.from(getWorkWiseAuthToken()?.split('.')[1], 'base64').toString());
    setUserInfo(info);
  }, []);

  return <Loader />
}