import React, { useEffect, useState } from "react";
import { Drawer, Form, Menu, Dropdown } from "antd";
import "./editTable.css";
import { useSpreadSheet } from "../../context/spreadsheet-context";
import SubForm from "./subForm";
import { updateSheet } from "../../utils/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditPivotTable = ({ open, setOpen }) => {

  const { id } = useParams();
  const { spreadsheetInstanceRef } = useSpreadSheet();
  const { pivotData } = spreadsheetInstanceRef?.current?.sheet?.data;
  let headers = pivotData?.headers;
  const [filteredRowOptions, setFilteredRowOptions] = useState(
    (headers ? headers : []).filter((option) => option.trim() !== "")
  );
  let valuesHeaders = pivotData?.data?.map((item) => item[0]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelection = (value, type) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    const selectedColumnData = pivotData.data.find((item) => item[0] === value);
    pivotData.headers = pivotData.headers.filter((header) => header !== value);
    setFilteredRowOptions(pivotData.headers);
    if (selectedColumnData && type === "row") {
      setSelectedRows((prevRow) => [...prevRow, selectedColumnData]);
      pivotData.selectedRowData.push(selectedColumnData);
    } else if (selectedColumnData && type === "column") {
      setSelectedColumns((prevCol) => [...prevCol, selectedColumnData]);
      pivotData.selectedColData.push(selectedColumnData);
    }
    displayInSheet();
  };

  const clearSelectedRangeCells = (data, preList, view) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    let isExistingSheet = pivotData.isExistingSheet;
    let existingSheetRange = pivotData.existingSheetRange;

    if (isExistingSheet && existingSheetRange) {
      let loopLength = pivotData.data.length + pivotData.selectedValueData.length;
      let rowCount = pivotData.data.reduce((r, s) => r.length > s.length ? r.length : s.length, 0) + existingSheetRange[0];
      if (view == 'both') {
        if (preList) {
          rowCount = rowCount + preList.length;
        }
      }
      for (let i = 0; i <= loopLength; i++) {
        let rowCi = i + existingSheetRange[1];
        let colRi = i + existingSheetRange[0];
        for (let j = 0; j <= rowCount; j++) {
          let rowRi = j + existingSheetRange[0];
          let colCi = j + existingSheetRange[1];
          sheet.data.rows.deleteCell(rowRi, rowCi, 'text');
          sheet.data.rows.deleteCell(rowRi, rowCi, 'format');
          sheet.data.rows.deleteCell(colRi, colCi, 'text');
          sheet.data.rows.deleteCell(colRi, colCi, 'format');
        }
      }
    }
    else {
      return;
    }
  }

  const makeGroup = (data) => {
    if (!data || !data[0]) {
      console.error('Invalid data format. Expected an array with at least one element.');
      return;
    }
    let discardValues = [];
    let arrangedValues = [];
    data[0].forEach((item, ind) => {
      let skipIndex = [];
      if (discardValues.includes(item)) return;
      data[0].forEach((it, childIndex) => {
        if (item === it && !skipIndex.includes(childIndex)) {
          let group = [item];
          for (let i = 1; i < data.length; i++) {
            if (data[i]) {
              group.push(data[i][childIndex]);
            }
          }
          arrangedValues.push(group);
          skipIndex.push(childIndex);
          discardValues.push(item);
        }
      });
    });
    return arrangedValues;
  };

  const displayInSheet = (removedElement) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;

    if (pivotData.isExistingSheet === false) {
      sheet.data.rows._ = {};
    }
    const cstyle = {
      bgcolor: "#0170c1",
      color: "#ffffff",
      font: { 0: "b", 1: "o", 2: "l", 3: "d", bold: true },
    };
    const property = {
      mode: "outside",
      style: "thick",
      color: "#0170c1",
      rowIndex: 0,
      colIndex: 0,
    };
    if (pivotData.selectedRowData.length === 0 &&
      pivotData.selectedColData.length === 0) {
      if (pivotData.isExistingSheet) {
        clearSelectedRangeCells(pivotData.selectedValueData.map((item) => item.data), removedElement, 'value');
      }
      displayOnlyValues(cstyle, property);
      sheet.table.render();
      return;
    }
    if (pivotData.selectedRowData.length === 0 && pivotData.selectedColData.length >= 1) {
      if (pivotData.isExistingSheet) {
        clearSelectedRangeCells([...pivotData.selectedColData], removedElement, 'col');
      }
      displayValuesInCoulmn(cstyle, property);
      sheet.table.render();
      return;
    }
    if (pivotData.selectedColData.length === 0 && pivotData.selectedRowData.length >= 1) {
      if (pivotData.isExistingSheet) {
        clearSelectedRangeCells(pivotData.selectedRowData, removedElement, 'row');
      }
      displayRows(cstyle, 'row',);
      sheet.table.render();
      return;
    }
    if (pivotData.selectedRowData.length >= 1 &&
      pivotData.selectedColData.length >= 1) {
      clearSelectedRangeCells(null, removedElement, 'both');
      displayValuesInBoth(cstyle, property, 'both');
      sheet.table.render();
      return;
    }
    if (pivotData.selectedRowData.length === 0 || pivotData.selectedColData.length === 0) {
      clearSelectedRangeCells(pivotData.selectedRowData, removedElement, 'row');
    }
    sheet.table.render();
  };

  function calculateResult(dataa = null, selectedData, formula) {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    let calData = selectedData && selectedData.slice(1);
    let combineData = [
      ...pivotData.selectedRowData.map(row => row.slice(1)),
      ...pivotData.selectedColData.map(row => row.slice(1)),
      calData
    ];
    let data = dataa ? [...dataa.map(row => row.slice(1)), calData] : combineData
    let arrangedValues = makeGroup(data);
    let result = [];
    let processedRows = new Set();

    arrangedValues && arrangedValues.forEach((item, index) => {
      let pair = item;
      let count = 0;
      let joinedValue = pair.join();
      arrangedValues.map((item2, index2) => {
        let pair2 = item2;
        if (joinedValue === pair2.join()) {
          count++;
        }
      });
      let selectedVal = Number(item[data.length - 1]);
      let itemResult;
      switch (formula) {
        case "sum":
          itemResult = selectedVal ? Array(count).fill(selectedVal).reduce((a, b) => a + b, 0) : 0;
          break;
        case "countA":
          itemResult = count;
          break;
        case "min":
          itemResult = selectedVal ? Math.min(...Array(count).fill(selectedVal)) : undefined;
          break;
        case "max":
          itemResult = selectedVal ? Math.max(...Array(count).fill(selectedVal)) : undefined;
          break;
        default:
          itemResult = [];
      }
      if (!processedRows.has(joinedValue)) {
        result.push({
          row: item.slice(0, -1),
          result: itemResult,
        });
        processedRows.add(joinedValue);
      }
    });
    if (data.length === 2) {
      result = calculateResultForSingle(data.slice(0, -1), result, formula);
    }
    return result;
  }

  const calculateResultForSingle = (data, result, formula) => {
    let uniqueRows = new Map();
    let calData = [...result];

    calData.forEach((resultData) => {
      let joinRow = resultData.row.join();
      if (!uniqueRows.has(joinRow)) {
        let countResult;
        if (formula === 'countA') {
          let dataa = data.flat();
          countResult = dataa.filter((row) => row === joinRow).length;
        }
        uniqueRows.set(joinRow, {
          row: resultData.row,
          result: formula === 'countA' ? countResult : formatDecimal(resultData.result),
        });
      } else {
        switch (formula) {
          case 'sum':
            let sum = uniqueRows.get(joinRow).result + resultData.result;
            uniqueRows.get(joinRow).result = formatDecimal(sum);
            break;
          case 'min':
            uniqueRows.get(joinRow).result = Math.min(
              uniqueRows.get(joinRow).result,
              formatDecimal(resultData.result)
            );
            break;
          case 'max':
            uniqueRows.get(joinRow).result = Math.max(
              uniqueRows.get(joinRow).result,
              formatDecimal(resultData.result)
            );
            break;
          default:
            break;
        }
      }
    });
    let updatedResult = Array.from(uniqueRows.values());
    return updatedResult;
  };

  const formatDecimal = (value) => {
    return typeof value === 'number' && Number.isFinite(value) && value % 1 !== 0
      ? parseFloat(value.toFixed(2))
      : value;
  };

  const displayValuesInBoth = (cstyle, property) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    let rowData = displayRows(cstyle);
    let colData = displayCoulmns(cstyle);
    let groupData = makeGroup([
      ...pivotData.selectedRowData.map(row => row.slice(1)),
      ...pivotData.selectedColData.map(row => row.slice(1)),
    ]);
    let selectedFormulaData = [...pivotData.selectedValueData];
    // console.log('okkkk rowdata', rowData, 'coldata', colData, 'groupdata', groupData);
    // selectedFormulaData && selectedFormulaData.forEach((data, i) => {
    //   let resultData = calculateResult(data.data);
    //   resultData.forEach((resultRow) => {
    //     let correspondingRowIndex = rowData.findIndex(arrRow => arrRow.join() === resultRow.row[0]);
    //     let correspondingColIndex = colData.findIndex(arrRow => arrRow.join() === resultRow.row[1]);
    //     let ri = pivotData.selectedColData.length + correspondingRowIndex;
    //     let ci = pivotData.selectedRowData.length + correspondingColIndex;
    //     sheet.data.setSelectedCellText(resultRow.result == 0 || isNaN(resultRow.result) ? '0' : resultRow.result, 'input', ri, ci);
    //     console.log('okkkk ind', ri, ci, correspondingRowIndex, correspondingColIndex);

    //   });
    // });
  };

  const displayRows = (cstyle) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    let isExistingSheet = pivotData.isExistingSheet;
    let existingSheetRange = pivotData.existingSheetRange;

    let rowData = pivotData.selectedRowData;
    let arrangedValues = makeGroup(rowData);
    let globalIndex = pivotData.selectedColData.length;
    let selectedFormulaData = pivotData.selectedValueData;

    const tableHeaders = arrangedValues[0];
    tableHeaders && tableHeaders.forEach((header, index) => {
      let ri = globalIndex;
      let ci = index;
      if (isExistingSheet && existingSheetRange) {
        ri = globalIndex + existingSheetRange[0];
        ci = ci + existingSheetRange[1];
      }
      const cell = sheet.data.rows.getCellOrNew(ri, ci);
      cell.style = sheet.data.addStyle(cstyle);
      sheet.data.setSelectedCellText(header, "input", ri, ci);
    });

    selectedFormulaData.forEach((data, i) => {
      let ri = globalIndex;
      let ci = tableHeaders.length + i;
      if (isExistingSheet && existingSheetRange) {
        ri = globalIndex + existingSheetRange[0];
        ci = ci + existingSheetRange[1];
      }
      let cell = sheet.data.rows.getCellOrNew(ri, ci);
      let formulaStyle = {
        bgcolor: "#525252",
        color: "#ffffff",
      };
      cell.style = sheet.data.addStyle(formulaStyle);
      sheet.data.setSelectedCellText(
        ` ${data.formula.toUpperCase()} of ${data.data[0]} `,
        "input",
        ri,
        ci
      )
    });

    const displayedValues = new Set();
    let singleRowIndex = 1;

    if (selectedFormulaData.length == 0) {
      arrangedValues && arrangedValues.slice(1).forEach((row, rowIndex) => {
        if (!displayedValues.has(row[0])) {
          let ri = pivotData.selectedRowData.length == 1 ? singleRowIndex + globalIndex : rowIndex + globalIndex + 1
          let ci = 0;
          if (isExistingSheet && existingSheetRange) {
            ri = ri + existingSheetRange[0];
            ci = ci + existingSheetRange[1];
          }
          sheet.data.setSelectedCellText(row[0], 'input', ri, ci);
          displayedValues.add(row[0]);
          singleRowIndex++;
        }
        let ri = rowIndex + globalIndex + 1;
        let ci = 1;
        if (isExistingSheet && existingSheetRange) {
          ri = ri + existingSheetRange[0];
          ci = ci + existingSheetRange[1];
        }
        row.slice(1).forEach((value, valueIndex) => {
          sheet.data.setSelectedCellText(value, 'input', ri, ci + valueIndex);
        });
      });
    }
    else {
      selectedFormulaData && selectedFormulaData.forEach((data, i) => {
        let resultData = calculateResult(rowData, data.data, data.formula);
        resultData.forEach((result, resultIndex) => {
          if (!displayedValues.has(result.row[0])) {
            let ri = resultIndex + 1 + globalIndex;
            let ci = 0;
            if (isExistingSheet && existingSheetRange) {
              ri = ri + existingSheetRange[0];
              ci = ci + existingSheetRange[1];
            }
            sheet.data.setSelectedCellText(result.row[0], 'input', ri, ci);
            displayedValues.add(result.row[0]);
          }
          let rowRi = resultIndex + 1 + globalIndex;
          let rowCi = 1;
          if (isExistingSheet && existingSheetRange) {
            rowRi = rowRi + existingSheetRange[0];
            rowCi = rowCi + existingSheetRange[1];
          }
          result.row.slice(1).forEach((value, valueIndex) => {
            sheet.data.setSelectedCellText(value, 'input', rowRi, rowCi + valueIndex);
          });
          let resultRi = resultIndex + 1 + globalIndex;
          let resultCi = result.row.length;
          if (isExistingSheet && existingSheetRange) {
            resultRi = resultRi + existingSheetRange[0];
            resultCi = resultCi + existingSheetRange[1];
          }
          sheet.data.setSelectedCellText(result.result == 0 || isNaN(result.result) ? '0' : result.result,
            'input', resultRi, resultCi + i);
        });
      });
    }

    return arrangedValues;
  }
  const displayCoulmns = (cstyle) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    let colData = [...pivotData.selectedColData];
    let arrangedValues = makeGroup(colData);
    let globalIndex = pivotData.selectedRowData.length + pivotData.selectedValueData.length;
    let isExistingSheet = pivotData.isExistingSheet;
    let existingSheetRange = pivotData.existingSheetRange;

    const tableHeaders = arrangedValues[0];
    tableHeaders && tableHeaders.forEach((header, index) => {
      let ri = 0;
      let ci = index + globalIndex;
      if (isExistingSheet && existingSheetRange) {
        ri = ri + existingSheetRange[0];
        ci = ci + existingSheetRange[1];
      }
      const cell = sheet.data.rows.getCellOrNew(ri, ci);
      cell.style = sheet.data.addStyle(cstyle);
      sheet.data.setSelectedCellText(header, "input", ri, ci);
    });

    const displayedValues = new Set();
    let singleRowIndex = 0;
    arrangedValues && arrangedValues.slice(1).forEach((row, rowIndex) => {
      if (!displayedValues.has(row[0])) {
        let ri = 1;
        let ci = pivotData.selectedColData.length == 1 ? singleRowIndex : rowIndex
        if (isExistingSheet && existingSheetRange) {
          ri = ri + existingSheetRange[0];
          ci = ci + existingSheetRange[1];
        }
        sheet.data.setSelectedCellText(row[0], 'input', ri, ci + globalIndex);
        displayedValues.add(row[0]);
        singleRowIndex++;
      }
      let ri = 0;
      let ci = rowIndex + globalIndex;
      if (isExistingSheet && existingSheetRange) {
        ri = ri + existingSheetRange[0];
        ci = ci + existingSheetRange[1];
      }
      row.slice(1).forEach((value, valueIndex) => {
        sheet.data.setSelectedCellText(value, 'input', ri + valueIndex + 2, ci);
      });
    });
    return arrangedValues;
  }

  const displayOnlyValues = (cstyle, property) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    let selectedFormulaData = [...pivotData.selectedValueData];
    let isExistingSheet = pivotData.isExistingSheet;
    let existingSheetRange = pivotData.existingSheetRange;
    if (selectedFormulaData.length === 0) {
      let payload = {
        ...sheet.data.getData(),
        pivotData: {
          ...pivotData,
          selectedValueData: [],
        }
      }
      updateSheet(id, payload);
      return;
    }
    selectedFormulaData.forEach((data, i) => {
      let result;

      switch (data.formula) {
        case 'sum':
          result = data.data.slice(1).reduce((acc, value) => acc + Number(value), 0);
          break;
        case 'countA':
          result = data.data.slice(1).filter(value => value !== '').length;
          break;
        case 'min':
          result = Math.min(...data.data.slice(1).map(value => Number(value)));
          break;
        case 'max':
          result = Math.max(...data.data.slice(1).map(value => Number(value)));
          break;
        default:
          break;
      }
      let ri = 0;
      let ci = i;
      if (isExistingSheet && existingSheetRange) {
        ri = ri + existingSheetRange[0];
        ci = ci + existingSheetRange[1];
      }
      result = formatDecimal(result);
      let cell = sheet.data.rows.getCellOrNew(ri, ci);
      let formulaStyle = {
        bgcolor: "#525252",
        color: "#ffffff",
      };
      cell.style = sheet.data.addStyle(formulaStyle);

      sheet.data.setSelectedCellText(
        ` ${data.formula.toUpperCase()} of ${data.data[0]}`,
        "input",
        ri,
        ci
      );
      sheet.data.setSelectedCellText(
        result === undefined || isNaN(result) ? "0" : result,
        "input",
        ri + 1,
        ci
      );
    });

    // sheet.data.setSelectedCellAttr("border", {
    //   ...property,
    //   rowIndex: 1,
    //   colIndex: selectedFormulaData.length - 1,
    // });
  };

  const displayValuesInCoulmn = (cstyle, property) => {

    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    let selectedFormulaData = [...pivotData.selectedValueData];
    let colData = [...pivotData.selectedColData];
    let arrangedValues = makeGroup(colData);
    let globalIndex = colData.length;
    let isExistingSheet = pivotData.isExistingSheet;
    let existingSheetRange = pivotData.existingSheetRange;

    selectedFormulaData.forEach((data, i) => {
      let ri = globalIndex + 1 + i;
      let ci = 0;
      if (isExistingSheet && existingSheetRange) {
        ri = ri + existingSheetRange[0];
        ci = ci + existingSheetRange[1];
      }
      let cell = sheet.data.rows.getCellOrNew(ri, ci);
      let formulaStyle = {
        bgcolor: "#525252",
        color: "#ffffff",
      };
      cell.style = sheet.data.addStyle(formulaStyle);
      sheet.data.setSelectedCellText(
        ` ${data.formula.toUpperCase()} of ${data.data[0]} `,
        "input",
        ri,
        ci
      )
    });
    const tableHeaders = arrangedValues[0];
    tableHeaders && tableHeaders.forEach((header, index) => {
      let ri = 0;
      let ci = selectedFormulaData.length == 0 ? index : index + 1;
      if (isExistingSheet && existingSheetRange) {
        ri = ri + existingSheetRange[0];
        ci = ci + existingSheetRange[1];
      }
      const cell = sheet.data.rows.getCellOrNew(ri, ci);
      cell.style = sheet.data.addStyle(cstyle);
      sheet.data.setSelectedCellText(header, "input", ri, ci);
    });
    const displayedValues = new Set();
    let singleRowIndex = 0;
    if (selectedFormulaData.length == 0) {
      arrangedValues && arrangedValues.slice(1).forEach((row, rowIndex) => {
        if (!displayedValues.has(row[0])) {
          let ci = pivotData.selectedColData.length == 1 ? singleRowIndex : rowIndex;
          let ri = 1;
          if (isExistingSheet && existingSheetRange) {
            ri = ri + existingSheetRange[0];
            ci = ci + existingSheetRange[1];
          }
          sheet.data.setSelectedCellText(row[0], 'input', ri, ci);
          displayedValues.add(row[0]);
          singleRowIndex++;
        }
        let ri = 0;
        let ci = rowIndex;
        if (isExistingSheet && existingSheetRange) {
          ri = ri + existingSheetRange[0];
          ci = ci + existingSheetRange[1];
        }
        row.slice(1).forEach((value, valueIndex) => {
          sheet.data.setSelectedCellText(value, 'input', ri + valueIndex + 2, ci);
        });
      });
    }
    else {
      selectedFormulaData && selectedFormulaData.forEach((data, i) => {
        let resultData = calculateResult(colData, data.data, data.formula);
        resultData.forEach((result, resultIndex) => {
          if (!displayedValues.has(result.row[0])) {
            let ri = 1;
            let ci = resultIndex + 1;
            if (isExistingSheet && existingSheetRange) {
              ri = ri + existingSheetRange[0];
              ci = ci + existingSheetRange[1];
            }
            sheet.data.setSelectedCellText(result.row[0], 'input', ri, ci);
            displayedValues.add(result.row[0]);
          }
          let rowRi = 2;
          let rowCi = resultIndex + 1;
          if (isExistingSheet && existingSheetRange) {
            rowRi = rowRi + existingSheetRange[0];
            rowCi = rowCi + existingSheetRange[1];
          }
          result.row.slice(1).forEach((value, valueIndex) => {
            sheet.data.setSelectedCellText(value, 'input', rowRi + valueIndex, rowCi);
          });
          let resultRi = globalIndex + 1 + i;
          let resultCi = resultIndex + 1;
          if (isExistingSheet && existingSheetRange) {
            resultRi = resultRi + existingSheetRange[0];
            resultCi = resultCi + existingSheetRange[1];
          }
          sheet.data.setSelectedCellText(result.result == 0 || isNaN(result.result) ? '0' : result.result,
            'input', resultRi, resultCi);
        });
      });
    }
  }

  const handleValueSelect = (selectedValue) => {
    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    const data = pivotData.data.find((item) => item[0] === selectedValue);
    let payload = {
      data: data,
      formula: "sum",
    };
    pivotData.selectedValueData.push(payload);
    setSelectedValues((prevValue) => [...prevValue, payload]);
    displayInSheet();
  };

  const menu = (type) => (
    <Menu>
      {filteredRowOptions.length > 0 ? (
        filteredRowOptions.map((option, index) => (
          <Menu.Item key={option} onClick={() => handleSelection(option, type)}>
            {option}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>
          <span>No data</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const ValueMenu = () => (
    <Menu>
      {valuesHeaders && valuesHeaders.length > 0 ? (
        valuesHeaders.map((option, index) => (
          <Menu.Item key={option} onClick={() => handleValueSelect(option)}>
            {option}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>
          <span>No data</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const handelOpen = () => {
    const spreadsheetData = spreadsheetInstanceRef?.current?.sheet?.data;
    if (spreadsheetData) {
      const { pivotData } = spreadsheetData;
      if (pivotData) {
        pivotData.selectedRowData?.map((item) => {
          setSelectedRows((prevRow) => [...prevRow, item]);
        });
        pivotData.selectedColData?.map((item) => {
          setSelectedColumns((prevCol) => [...prevCol, item]);
        });
        pivotData.selectedValueData?.map((item) => {
          setSelectedValues((preValue) => [...preValue, item]);
        });
      } else {
        console.error("okkkPivot data is undefined or null");
      }
    } else {
      console.error("okkkSpreadsheet data is undefined or null");
    }
  };

  useEffect(() => {
    if (open) {
      handelOpen();
    }
  }, [open]);

  useEffect(() => {
    const { sheet } = spreadsheetInstanceRef.current;
    const handleBodyClick = (event) => {

      const isEditIcon = (element) => {
        while (element) {
          if (element.id === 'edit-pivot-table') {
            return true;
          }
          element = element.parentElement;
        }
        return false;
      };

      const myHeaderElement = document.getElementById("myy-header");
      const sheetHeader = document.getElementById("my-sheet-bottombar");
      const isEditIconCliked = isEditIcon(event.target);
      if (isEditIconCliked === true) {
        return;
      }
      if (myHeaderElement && myHeaderElement.contains(event.target)) {
        setOpen(false);
      } else if (sheetHeader && event.target.id === sheetHeader.id) {
        setOpen(false);
        const editIcon = document.getElementById("edit-pivot-table");
        if (sheet.data.pivotData) {
          if (!sheet.data.pivotData.isEdit) {
            if (editIcon) {
              editIcon.style.display = "none";
            }
          } else if (sheet.data.pivotData.isEdit) {
            if (editIcon) {
              editIcon.style.display = "flex";
            }
          }
        }
      }

    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handelClose = (e) => {
    e.preventDefault();
    const editIcon = document.getElementById("edit-pivot-table");
    if (editIcon) {
      editIcon.style.display = "flex";
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const editIcon = document.getElementById("edit-pivot-table");
      if (editIcon) {
        editIcon.style.display = "none";
      }
    }
  }, [open]);

  return (
    <>
      <Drawer
        id="my-edit-PivotTable-Drawer"
        title="Edit Pivot Table"
        placement="right"
        onClose={handelClose}
        visible={open}
        maskClosable={false}
        mask={false}
        width={400}
        style={{ top: "58px", right: "4px" }}
      >
        <Form>
          <div className="form-style">
            <Form.Item label="Rows">
              <Dropdown overlay={menu("row")} trigger={["click"]}>
                <button className="btn-style" style={{ marginLeft: "28px" }}>
                  Add
                </button>
              </Dropdown>
            </Form.Item>
            <div className="sub-form-box">
              {selectedRows.map((item, index) => {
                return (
                  <SubForm
                    type="row"
                    key={index}
                    displayInSheet={displayInSheet}
                    filteredRowOptions={filteredRowOptions}
                    selectedValue={item[0]}
                    selectedItemList={selectedRows}
                    setSelectedItemList={setSelectedRows}
                    id={index}
                  />
                );
              })}
            </div>
            <Form.Item label="Columns" style={{ marginTop: "8px" }}>
              <Dropdown overlay={menu("column")} trigger={["click"]}>
                <button className="btn-style">Add</button>
              </Dropdown>
            </Form.Item>
            <div className="sub-form-box">
              {selectedColumns.map((item, index) => {
                return (
                  <SubForm
                    type="column"
                    key={index}
                    displayInSheet={displayInSheet}
                    filteredRowOptions={filteredRowOptions}
                    selectedValue={item[0]}
                    selectedItemList={selectedColumns}
                    setSelectedItemList={setSelectedColumns}
                    id={index}
                  />
                );
              })}
            </div>
            <Form.Item label="Values" style={{ marginTop: "8px" }}>
              <Dropdown overlay={ValueMenu} trigger={["click"]}>
                <button className="btn-style" style={{ marginLeft: "25px" }}>
                  Add
                </button>
              </Dropdown>
            </Form.Item>
            <div className="sub-form-box">
              {selectedValues.map((item, index) => {
                return (
                  <SubForm
                    type="value"
                    key={index}
                    selectedValue={item.data[0]}
                    selectedFormula={item.formula}
                    displayInSheet={displayInSheet}
                    selectedItemList={selectedValues}
                    setSelectedItemList={setSelectedValues}
                    id={index}
                  />
                );
              })}
            </div>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default EditPivotTable;