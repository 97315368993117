export class UserSelector {
  spreadsheetInstance;
  ele;
  parentEle;

  constructor(spreadsheetInstanceRef) {
    this.spreadsheetInstance = spreadsheetInstanceRef;
    if (this.spreadsheetInstance) {
      this.initializeSelector();
    }
  }

  initializeSelector = () => {
    this.ele = document.createElement("div");
    this.ele.setAttribute(
      "style",
      `visibility: visible; height: 22.8px; width: 97.8px; border: 1px solid red; position: absolute; top: -0.8px; left: -0.8px; z-index: 30000; background-color: rgba(250, 10, 10,0.1) `
    );
    this.parentEle = this.spreadsheetInstance.current.sheet.overlayerCEl.el;
    this.parentEle.appendChild(this.ele);
  };

  updatePositions = (height, width, top, left) => {
    this.ele.setAttribute(
      "style",
      `visibility: visible; height: ${height}px; width: ${width}px; border: 1px solid red; position: absolute; top: ${top}px; left: ${left}px; z-index: 30000;background-color: rgba(250, 10, 10,0.1);`
    );
  };

  deleteSelector = () => {
    this.parentEle.removeChild(this.ele);
  }

  hide = () => {
    this.ele.setAttribute(
      "style",
      `visibility: hidden;`,
    );
  }
}

// 2x UserSelectors ->
// object -> "socket.id"
//if object.socketid exists then give back the instance associated with that id
// instance.updateAttributes -> top left width height
//    const ele = document.createElement("div");
//ele.setAttribute("style",`height: ${selData.height}px; width: ${selData.width}px; border: 1px solid red; position: absolute; top: ${selData.top}px; left: ${selData.left}px; z-index: 30000`);
//console.log(spreadsheetInstanceRef.current.sheet.overlayerCEl.el.appendChild(ele));
//UserSelector.addSelector()
