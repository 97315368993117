import { Chart } from "chart.js";
import { v4 } from "uuid";

class Charts {
    constructor(sheet) {
        this.sheet = sheet;
        this.clickHandler = this.handleClick.bind(this);
        this.dragStartHandler = this.dragStart.bind(this);
    }

    resetData(data, container) {
        this.clearCharts(container);
        this.drawCharts(data, container);
    }

    createChartContainer(chartId, container, message = null) {
        const chartContainer = document.createElement('div');
        chartContainer.className = 'chart';
        chartContainer.id = chartId;

        const sheetBounds = this.sheet.el.el.getBoundingClientRect();

        const defaultLeft = 300 + this.sheet.data.charts.length * 120;
        const defaultTop = 245 + this.sheet.data.charts.length * 80;

        let left = `${defaultLeft}px`;
        let top = `${defaultTop}px`;

        if ((defaultLeft + 500) >= sheetBounds.right) {
            left = `${sheetBounds.right - 500}px`;
        }
        if (defaultTop + 300 >= sheetBounds.bottom) {
            top = `${sheetBounds.bottom - 300}px`;
        }
        chartContainer.style.cssText = `
            width: 500px;
            position: fixed;
            height: 300px;
            top: ${top};
            left: ${left};
            z-index: 9999;
            background: white;
            border: 2px solid silver;
        `;

        container.el.appendChild(chartContainer);

        if (message) {
            const messageElement = document.createElement('p');
            messageElement.textContent = message;
            messageElement.style.cssText = `
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                color: #7a7777;
                top: 120px;
                left: 50px;
            `;
            chartContainer.appendChild(messageElement);
        }
        return chartContainer;
    }

    createContextMenu(e, chartContainer) {
        const existingDotsIcon = chartContainer.querySelector('.dots-icon');
        if (!existingDotsIcon) {
            const dotsIcon = document.createElement('div');
            dotsIcon.className = 'dots-icon';
            dotsIcon.innerHTML = '• • •';
            dotsIcon.style.cssText = `
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                font-size: 18px;
                color:'#999';
                display: none;
            `;
            const contextMenu = document.createElement('div');
            contextMenu.className = 'context-menu';
            contextMenu.innerHTML = 'Delete';
            contextMenu.style.cssText = `
            position: absolute;
            top: 0px;
            left: 50px;
            background: white;
            border: 1px solid silver;
            cursor: pointer;
            font-size:12px;
            padding: 2px 8px;
            display:none;
        `;
            dotsIcon.appendChild(contextMenu);
            dotsIcon.addEventListener('click', () => {
                contextMenu.style.display = 'block';
            })
            chartContainer.appendChild(dotsIcon);
            contextMenu.addEventListener('click', (event) => this.handelDeleteChart(event, chartContainer));
        }
        else {
            return;
        }
    }

    addChart(container, selectedData, chartType) {
        const chartId = `chart-${v4()}`;
        selectedData = selectedData.slice(1); // exclude first row
        const labels = selectedData.map(row => row[0]);
        const chartData = selectedData.map(row => row[1]);
        const NumericOrAllEmpty = chartData.some(item => item === '' || /^-?\d+(\.\d+)?$/.test(item));
        let chartContainer;
        if ((chartData.length <= 1 && labels.length <= 1)) {
            const message = 'Select cells and add a chart for visualizing your data';
            chartContainer = this.createChartContainer(chartId, container, message);
            this.storeChart({ id: chartId, type: 'message', message }, chartContainer.style);
        }
        else if (!NumericOrAllEmpty) {
            const message = ' Numeric values required for visualizing data';
            chartContainer = this.createChartContainer(chartId, container, message);
            this.storeChart({ id: chartId, type: 'message', message }, chartContainer.style);
        } else {
            chartContainer = this.createChartContainer(chartId, container);
            chartContainer.style.borderColor = 'red';
            const canvas = document.createElement('canvas');
            chartContainer.appendChild(canvas);
            const options = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: { position: 'top', display: false },
                    title: { display: true, text: `${chartType}Chart` },
                },
                scales: { x: { min: 0, max: 25 }, y: { beginAtZero: true } },
            };

            new Chart(canvas, {
                type: chartType === 'area' ? 'line' : chartType,
                data: {
                    labels,
                    datasets: [{
                        label: 'Radius',
                        data: chartData,
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        borderWidth: 2,
                        borderRadius: 5,
                        borderSkipped: false,
                        fill: chartType === 'area',
                    }],
                },
                options,
            });

            this.storeChart({
                id: chartId,
                type: 'chart',
                chartType: chartType == 'area' ? 'line' : chartType,
                data: {
                    labels, datasets: [{
                        label: 'Radius', data: chartData, borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.5)', borderWidth: 2, borderRadius: 5,
                        borderSkipped: false,
                        fill: chartType === 'area'
                    }]
                }, options
            }, chartContainer.style
            );
        }
        document.addEventListener('click', event => this.clickHandler(chartContainer, event));
        chartContainer.addEventListener('click', (event) => this.createContextMenu(event, chartContainer));
        chartContainer.addEventListener('mousedown', event => this.dragStartHandler(chartContainer, event));
    }
    clearCharts(container) {
        this.sheet.data.charts.forEach((chart) => {
            const element = document.getElementById(chart.id);
            this.removeEventListeners(element);
        });
        container.el.innerHTML = '';
    }
    drawCharts(data, container) {
        data.charts.forEach((storedItem, index) => {
            const chartContainer = this.createChartContainer(storedItem.id, container, storedItem.type === 'message' ? storedItem.message : null);

            if (storedItem.type === 'chart' && storedItem.data) {
                const data = { labels: storedItem.data.labels, datasets: [{ ...storedItem.data.datasets[0] }] };
                const canvas = document.createElement('canvas');
                chartContainer.appendChild(canvas);
                new Chart(canvas, { type: storedItem.chartType, data, options: storedItem.options });
            }
            if (storedItem.position) {
                chartContainer.style.top = storedItem.position.top;
                chartContainer.style.left = storedItem.position.left;
            }
            document.addEventListener('click', event => this.clickHandler(chartContainer, event));
            chartContainer.addEventListener('click', (event) => this.createContextMenu(event, chartContainer));
            chartContainer.addEventListener('mousedown', event => this.dragStartHandler(chartContainer, event));
        });
    }

    storeChart(chartData, style) {
        this.sheet.data.charts.push({ ...chartData, position: { top: style.top, left: style.left } });
        let payload = this.sheet.data.getData();
        payload.charts = this.sheet.data.charts;
        this.sheet.trigger('change', payload);
    }

    handleClick(chartContainer, clickEvent) {
        const dotsIcon = chartContainer.querySelector('.dots-icon');
        if (!chartContainer.contains(clickEvent.target)) {
            chartContainer.style.borderColor = 'silver';
            if (dotsIcon) {
                chartContainer.removeChild(dotsIcon);
            }
            document.removeEventListener('click', this.clickHandler);
            chartContainer.removeEventListener('mousedown', this.dragStartHandler);
        }
        else {
            chartContainer.style.borderColor = '#4b89ff';
            dotsIcon.style.display = 'block';
        }
    }
    dragStart(chartContainer, event) {
        const { sheet } = this;
        let offsetX = event.clientX - chartContainer.getBoundingClientRect().left;
        let offsetY = event.clientY - chartContainer.getBoundingClientRect().top;

        const handleMouseMove = (moveEvent) => {
            this.sheet.data.charts.forEach((chart) => {
                const element = document.getElementById(chart.id);
                const dotsIcon = element.querySelector('.dots-icon');
                if (element) {
                    element.style.borderColor = 'silver';
                    if (dotsIcon) {
                        element.removeChild(dotsIcon);
                    }
                }
            });
            chartContainer.style.borderColor = '#4b89ff';
            const x = moveEvent.clientX - offsetX;
            const y = moveEvent.clientY - offsetY;

            const sheetBounds = sheet.el.el.getBoundingClientRect();
            const chartRect = chartContainer.getBoundingClientRect();

            if (
                x > sheetBounds.left &&
                x + chartRect.width < sheetBounds.right &&
                y > sheetBounds.top &&
                y + chartRect.height < sheetBounds.bottom
            ) {
                chartContainer.style.left = `${x}px`;
                chartContainer.style.top = `${y}px`;
            } else {
                const { verticalScrollbar, horizontalScrollbar, data } = sheet;
                const { top } = verticalScrollbar.scroll();
                const { left } = horizontalScrollbar.scroll();

                const moveY = (vertical) => {
                    const { rows } = data;
                    const ri = data.scroll.ri - vertical;
                    if (ri >= 0 && ri < rows.len) {
                        const rh = rows.getHeight(ri);
                        verticalScrollbar.move({ top: top - vertical * rh });
                    }
                };
                const moveX = (horizontal) => {
                    const { cols } = data;
                    const ci = data.scroll.ci - horizontal;
                    if (ci >= 0 && ci < cols.len) {
                        const cw = cols.getWidth(ci);
                        horizontalScrollbar.move({ left: left - horizontal * cw });
                    }
                };
                if (x < sheetBounds.left) {
                    moveX(1); // Scroll to the right
                } else if (x + chartRect.width > sheetBounds.right) {
                    moveX(-1); // Scroll to the left
                }
                if (y < sheetBounds.top) {
                    moveY(1); // Scroll down
                } else if (y + chartRect.height > sheetBounds.bottom) {
                    moveY(-1); // Scroll up
                }
            }
        };
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            const chartId = chartContainer.id;
            const storedChart = this.sheet.data.charts.find((chart) => chart.id === chartId);
            if (storedChart) {
                storedChart.position = { top: chartContainer.style.top, left: chartContainer.style.left };
                let payload = this.sheet.data.getData();
                payload.charts = this.sheet.data.charts;
                this.sheet.trigger('change', payload);
            }
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    removeEventListeners(element) {
        if (element) {
            document.removeEventListener('click', this.clickHandler);
            element.removeEventListener('mousedown', this.dragStartHandler);
        }
    }
    handelDeleteChart(event, chartContainer) {
        const dotsIcon = chartContainer.querySelector('.dots-icon');
        event.preventDefault();
        this.deleteChart(chartContainer.id);
        chartContainer.removeChild(dotsIcon);
    }
    handleOutsideContextMenuClick(contextMenu, outsideEvent) {
        if (!contextMenu.contains(outsideEvent.target)) {
            document.body.removeChild(contextMenu);
            document.removeEventListener('click', this.handleOutsideContextMenuClick);
        }
    }
    deleteChart(chartId) {
        const { sheet } = this;
        const chartIndex = this.sheet.data.charts.findIndex((chart) => chart.id === chartId);
        if (chartIndex !== -1) {
            this.sheet.data.charts.splice(chartIndex, 1);
            let payload = this.sheet.data.getData();
            payload.charts = this.sheet.data.charts;
            this.sheet.trigger('change', payload);
        }
        const chartContainer = document.getElementById(chartId);
        if (chartContainer) {
            chartContainer.remove();

        }
    }
}

export default Charts;
