import { CloseOutlined } from "@ant-design/icons";
import { Form, Select } from "antd";
import React, { useState } from "react";
import "./subForm.css";
import { useSpreadSheet } from "../../context/spreadsheet-context";

const SubForm = ({
  selectedValue,
  setSelectedItemList,
  selectedItemList,
  id,
  type,
  filteredRowOptions,
  displayInSheet,
  selectedFormula,
}) => {
  const { spreadsheetInstanceRef } = useSpreadSheet();
  const sheet = spreadsheetInstanceRef?.current;
  const { pivotData } = spreadsheetInstanceRef?.current?.sheet?.data;
  const [formula, setSelectedFormula] = useState("");

  const handleFormulaSelect = (value) => {
    setSelectedFormula(value);
    const { sheet } = spreadsheetInstanceRef.current;
    const { pivotData } = sheet.data;
    pivotData.selectedValueData[id] = { ...pivotData.selectedValueData[id], formula: value };
    setSelectedItemList((prevList) => {
      const updatedList = [...prevList];
      updatedList[id] = { ...updatedList[id], formula: value };
      return updatedList;
    });
    displayInSheet();
  };

  const handelDelete = (e) => {

    const preList = [...selectedItemList];
    const updateList = [...selectedItemList];
    const cardItem = updateList.find((item) => item[0] === selectedValue);
    const cardItemIndex = cardItem && updateList.indexOf(cardItem);

    if (type == "value") {
      pivotData.selectedValueData.splice(id, 1);
      updateList.splice(id, 1);
      setSelectedItemList(updateList);
    }
    else {
      const headerItem = pivotData.data.find((item) => item[0] === selectedValue);
      const headerItemIndex = headerItem && pivotData.data.indexOf(headerItem);
      filteredRowOptions.splice(headerItemIndex, 0, selectedValue);
      updateList.splice(cardItemIndex, 1);
      setSelectedItemList(updateList);
      pivotData.headers = filteredRowOptions;

      if (type == "row") {
        const rowItem = pivotData.selectedRowData.find((item) => item[0] === selectedValue);
        const rowItemIndex = rowItem && pivotData.selectedRowData.indexOf(rowItem);
        pivotData.selectedRowData.splice(rowItemIndex, 1);
      }
      if (type == "column") {
        const colItem = pivotData.selectedColData.find((item) => item[0] === selectedValue);
        const colItemIndex = colItem && pivotData.selectedColData.indexOf(colItem);
        pivotData.selectedColData.splice(colItemIndex, 1);
      }
    }
    displayInSheet(preList);
  };


  return (
    <div
      className="sub-form-container"
      style={{
        width: `${type != "value" ? "auto" : "120px"}`,
        height: 'auto',
        borderRadius: `${type != "value" ? "6px" : "16px"}`,
      }}
    >
      <Form layout="vertical">
        <div className="sub-form-header">
          <p
            className="sub-form-title"
            style={{
              fontSize: `${type != "value" ? 12 : 16}px`,
              justifyContent: `${type != "value" ? "center" : ""}`,
            }}
          >
            {selectedValue}
          </p>
          <CloseOutlined
            type="close"
            className="sub-form-close-icon"
            onClick={(e) => handelDelete(e)}
            style={{
              fontSize: `${type != "value" ? 9 : 13}px`,
              marginTop: `${type != "value" ? 5 : 8}px`,
            }}
          />
        </div>
        {type == "value" && (
          <Form.Item label="Summarize by:" className="sub-form-select-label">
            <Select
              // defaultValue="SUM"
              onChange={(value) => handleFormulaSelect(value)}
              className="sub-form-select"
              value={selectedFormula ? selectedFormula : formula}
            >
              <Select.Option value="sum">SUM</Select.Option>
              <Select.Option value="countA">COUNTA</Select.Option>
              <Select.Option value="min">MIN</Select.Option>
              <Select.Option value="max">MAX</Select.Option>
            </Select>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default SubForm;