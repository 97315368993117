import { Avatar, Badge } from 'antd';
import React, { useEffect, useState } from 'react';
import './members.css';
import { useDocument } from '../../Pages/SpreadsheetContainer/documentInfoContex';
import Modes from '../Header/modes';
// import { renderMouse } from '../../Socket';

const DocumentMembers = () => {
    const { documentInfo, userInfo } = useDocument();
    const [onlineUsers, setOnlineUsers] = useState([]);
    const colorOptions = ['#f56a00', '#7265e6', '#ffbf00', '#87d068', '#ff6b81', '#00a2ae'];

    const getInitials = (name) => {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        // renderMouse(setOnlineUsers);
        // connectedClients(setConnectedSocketID);
    }, []);

    const getRandomColor = () => {
        const newColor = '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
        if (newColor !== '#CF9FFF') {
            if (!colorOptions.includes(newColor)) {
                colorOptions.push(newColor);
                return newColor;
            }
        }
    };

    return (
    <div style={{display:'flex',marginTop:0}}>
        <Avatar.Group
            maxCount={4}
            maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
            }}
        >
            {documentInfo?.members?.length >= 1 && documentInfo.members.map((member, ind) => {
                if (userInfo.userId !== member.id) {
                    const backgroundColor = getRandomColor();
                    return (
                        <Badge
                            key={ind}
                            offset={["-6%", "98%"]}
                            style={{
                                width: "10px",
                                height: "10px",
                                backgroundColor: "green"
                            }}
                            dot={onlineUsers.some(onlineUser => onlineUser.userName == member.name)}
                        >
                            <Avatar
                                src={member.image}
                                style={{
                                    backgroundColor: backgroundColor,
                                    marginLeft: '-5px',
                                    width:'30px',
                                    height:'30px',
                                    lineHeight:'30px',
                                    fontSize:'13px'
                                }}
                            >
                                {getInitials(member.name)}
                            </Avatar>
                        </Badge>
                    );
                }
            })}  
        </Avatar.Group>
        </div>
    );
}

export default DocumentMembers;
