import { h } from './element';
import { cssPrefix } from '../config';
import { expr2cellRangeArgs } from '../core/alphabet';

let cellSelection;
let cellData;

function buildDivider() {
  return h('div', `${cssPrefix}-toolbar-divider2`);
}

export default class CellInput {
  constructor(data, s, isHide = false) {
    this.sheet = s;
    this.cellSelection = cellSelection;
    this.data = data;
    this.isHide = isHide;
    this.cellInputContainerEl = h('div', `${cssPrefix}-cell-input-container`);
    this.cellSelect = h('input', `${cssPrefix}-cell-select`);
    // .on('input', this.getCellSelect);
    this.cellInputBox = h('input', `${cssPrefix}-cell-input`)
      .on('input', this.setCellData);
    this.inputLabel = h('div', `${cssPrefix}-input-label`);
    this.inputLabel.el.innerHTML = 'fx';
    this.cellInputContainerEl
      .children(this.cellSelect, this.cellInputBox);
    this.cellInputContainerEl
      .children(this.cellSelect, buildDivider(), this.inputLabel,
        buildDivider(), this.cellInputBox);
    // this.test();
    this.cellSelect.el.addEventListener('keydown', (e) => {
      if (e.code === 'Enter') {
        this.getCellSelect(e);
      }
    });
    if (this.isHide) {
      this.cellInputContainerEl.hide();
    }
  }

  getCellSelect = (e) => {
    cellSelection = e.target.value;
    // console.log(cellSelection);
    const { sheet } = this;
    const maxRows = sheet.data.rows.len - 1;
    const maxCols = sheet.data.cols.len - 1;
    const val = expr2cellRangeArgs(cellSelection);
    if (!val) return;
    const [sri, sci, eri, eci] = val;
    if (sci > maxCols || eci > maxCols || sri > maxRows || eri > maxRows) return;
    const sRC = [sri, sci];
    const eRC = [eri, eci];
    // console.log(sRC, eRC);
    if (!((sRC[0] === eRC[0]) && (sRC[1] === eRC[1]))) {
      console.log('mult');
      console.log(val);
      sheet.selector.set(sri, sci);
      sheet.selector.setEnd(eri, eci);
    } else {
      console.log('single');
      console.log(val);
      sheet.selector.set(sri, sci);
      sheet.selector.setEnd(eri, eci);
    }
  }

  setCellData = (e) => {
    // if (!cellSelection) return;
    const { sheet } = this;
    cellData = e.target.value;
    // console.log(ri, ci, cellData);
    sheet.data.setSelectedCellText(cellData);
    sheet.table.render();
  }

  rowCols() {
    // const { ri, ci } = this.data.selector;
    const { sheet } = this;
    const [ri, ci] = sheet.selector.indexes;
    // console.log(ri, ci);
    // console.log(this.selector);
    return { ri, ci };
  }
}
