import React, { useRef,useState } from "react";
import Spreadsheet from "../x-data-spreadsheet";
import {
  emitSingularCellSelection,
  emitMultipleCellSelection,
  // emitEditedCellData,
  // emitCellStyle,
  emitOnChanged,
} from "../socket";


const SpreadSheetContext = React.createContext({});

const config = {
  mode: "read", // edit | read
  showToolbar: false,
  showGrid: true,
  showContextmenu: true,
  showBottomBar: true,
  view: {
    height: () => document.documentElement.clientHeight - 60,
    width: () => document.documentElement.clientWidth,
  },
  row: {
    len: 3000,
    height: 25,
  },
  col: {
    len: 26,
    width: 100,
    indexWidth: 60,
    minWidth: 60,
  },
  style: {
    bgcolor: "#ffffff",
    align: "left",
    valign: "middle",
    textwrap: false,
    strike: false,
    underline: false,
    color: "#0a0a0a",
    font: {
      name: "Helvetica",
      size: 10,
      bold: false,
      italic: false,
    },
  },
};

export const SpreadSheetCtxProviderRd = ({ children }) => {
  let spreadsheetInstanceRef = useRef(null);
  const [ currPos, setCurrPos ] = useState({});


  const spreadSheet_init = () => {
    // readOnly = true;
    let spreadsheetInstance = new Spreadsheet("#spreadsheet-container", config);
    spreadsheetInstanceRef.current = spreadsheetInstance;
  };

  // const onChanged = () => {
  //   spreadsheetInstanceRef.current.on('change', (data) => {
  //     emitOnChanged(data);
  //   });
  // };

  const onCellSelected = (id) => {
    spreadsheetInstanceRef.current.on("cell-selected", (cell, ri, ci) => {
      // console.log(cell, "onCellSelected");
      const sheetData = spreadsheetInstanceRef.current.data;
      // console.log(sheetData);
      const {selector} =spreadsheetInstanceRef.current.sheet
      emitSingularCellSelection({ id,selData: selector, cell: cell, rowIndex: ri, columnIndex: ci }); 
      // spreadsheetInstanceRef.current.on("change", (data) => {
      //   setTimeout(() => {
      //     emitOnChanged(data);
      //console.log(id);
      //   }, 5000);
      //   // console.log('sheetData:', data);
      // });
      const someData = spreadsheetInstanceRef.current.getData();
      // console.log(sheetData);
      // const styles = someData[0].styles;
      // console.log(styles);
      emitOnChanged(id, someData);
    });
  };

  const onCellsSelected = () => {
    spreadsheetInstanceRef.current.on(
      "cells-selected",
      (cell, { sri, sci, eri, eci }) => {
        // console.log({ sri, sci, eri, eci });
        const {selector}=spreadsheetInstanceRef.current.sheet
        emitMultipleCellSelection({
          selsData: selector,
          cell: cell,
          startRowIndex: sri,
          startColumnIndex: sci,
          endRowIndex: eri,
          endColumnIndex: eci,
        });
      }
    );
  };

  // const onCellEdited = () => {
  //   spreadsheetInstanceRef.current.on("cell-edited", (text, ri, ci) => {
  //     // console.log(text);
  //     const cStyle = getCellStyle(ri, ci);
  //     // console.log(ri, ci, cStyle, "emittedStyles");
  //     emitEditedCellData({
  //       dataText: text,
  //       rowIndex: ri,
  //       columnIndex: ci,
  //       cStyle: cStyle,
  //     });
  //     // getCell(ri, ci);

  //     /*       const { cellText, cellRowIndex, cellColumnIndex } =
  //       listenerUpdateCellData(); */
  //     // console.log(cellText, cellRowIndex, "LISTENER LOG")
  //   });
  // };

  // const onCellStyle = () => {
  //   spreadsheetInstanceRef.current.on("cell-style", (ri, ci) => {
  //     const cStyle = getCellStyle(ri, ci);
  //     // console.log(cStyle, 'onCellStyle');
  //     emitCellStyle({ rowIndex: ri, columnIndex: ci, cStyle: cStyle });

  //     // emitCellStyle({cStyle: cStyle})
  //   });
  // };

  // const onAddSheet = () => {
  //   spreadsheetInstanceRef.current.on("add-sheet", (datasLength) => {
  //     // console.log('onAddSheet', sheetIndex);
  //     emitAddSheet({ datasLength: datasLength });
  //   });
  // };

  /*  const onUpdateCellText = () => {
    spreadsheetInstanceRef.current.on("update-cell-data", (text, ri, ci) => {
      emitOnChangeCellData({ dataText: text, rowIndex: ri, columnIndex: ci });
      spreadsheetInstanceRef.current
      .cellText(cellRowIndex, cellColumnIndex, cellText)
      .reRender();
    });
  }; */

  // const getCell = (ri, ci) => {
  //   const thisCell = spreadsheetInstanceRef.current.cell(ri, ci);
  //   console.log(thisCell);
  // };

  // const getCellStyle = (ri, ci) => {
  //   const cellStyle = spreadsheetInstanceRef.current.cellStyle(ri, ci);
  //   return cellStyle;
  // };
  return (
    <SpreadSheetContext.Provider
      value={{
        spreadsheetInstanceRef,
        spreadSheet_init,
        onCellSelected,
        // onAddSheet,
        onCellsSelected,
        // onCellEdited,
        // onCellStyle,
        // onChanged,
       //getCellStyle,
       setCurrPos
      }}
    >
      {children}
    </SpreadSheetContext.Provider>
  );
};

export const useSpreadSheetRd = () => {
  return React.useContext(SpreadSheetContext);
};
