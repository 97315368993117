import React from "react";
import MyNav from "./Navigations.module.css";
import { FileTab } from "../NavTabs/FileTab";
import { EditTab } from "../NavTabs/EditTab";
import { InsertTab } from "../NavTabs/InsertTab";
import { ViewTab } from "../NavTabs/ViewTab";


const Navigations = () => {
  return (
    <div className={MyNav.navigationmain}>
      <FileTab />
      <EditTab />
      <InsertTab />
      <ViewTab />
    </div>
  );
};

export default Navigations;
