// import { useState } from "react";
import MySheetName from "./SheetName.module.css";
import { useDocument } from "../../Pages/SpreadsheetContainer/documentInfoContex";

const SheetName = ({ alreadyAligned }) => {
  const isMobile = window.screen.width < 769;

  const { documentInfo } = useDocument();
  // const [gridTitle, setGridTitle] = useState("");
  //for sending http request
  //   useEffect(() => {
  //     const interval = setTimeout(() => {
  //       console.log(gridTitle);
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }, [gridTitle]);

  // const handleTitleChange = (event) => {
  //   setGridTitle(event.target.value);
  // };
  let style = MySheetName['title'];
  if (!alreadyAligned) style = MySheetName['title2'];
  return (
    <div>
      <input
        id="milegrid-url"
        className={`${MySheetName.title} ${MySheetName.overflow}`}
        value={documentInfo?.name ? documentInfo.name : "Untitled Milegrid"}
        // onChange={handleTitleChange}
        // style={{
        //   pointerEvents: 'none', backgroundColor: '#fff',
        //   fontSize: isMobile ? '14px' : '16px'
        // }}
        readOnly
      />
      {/* <div id="milegrid-url"
        // className={MySheetName.title}
        className={MySheetName.titlewrapper}
        style={{
          // fontWeight: 600,
          width: 'max-content',
          maxWidth: '450px',
          overflow: "hidden",
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontSize:isMobile?'14px': '16px',
        }}>{documentInfo?.name ? documentInfo.name : "Untitled Milegrid"}</div> */}
      {/* <p className={style} id="milegrid-url"></p> */}
    </div>
  );
};

export default SheetName;
