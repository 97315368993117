export function getFormulaDescription(formulaName) {
    switch (formulaName) {
        case 'ABS':
            return `${formulaName}(value)`;
        case 'ACCRINT':
            return `${formulaName}(issue, first_payment, settlement, rate, redemption, frequency, [day_count_convention])`;
        case 'ACOS':
            return `${formulaName}(value)`;
        case 'ACOSH':
            return `${formulaName}(value)`;
        case 'ACOT':
            return `${formulaName}(value)`;
        case 'ACOTH':
            return `${formulaName}(value)`;
        case 'ADD':
            return `${formulaName}(value1, value2)`;
        case 'AGGREGATE':
            return `${formulaName}(function_num, options, ref1, [ref2], [...])`;
        case 'ARGS2ARRAY':
            return `${formulaName}(arg1, [arg2, ...])`;
        case 'ASIN':
            return `${formulaName}(value)`;
        case 'ASINH':
            return `${formulaName}(value)`;
        case 'ATAN':
            return `${formulaName}(value)`;
        case 'ATAN2':
            return `${formulaName}(x, y)`;
        case 'ATANH':
            return `${formulaName}(value)`;
        case 'AVEDEV':
            return `${formulaName}(value1, [value2, ...])`;
        case 'AVERAGE':
            return `${formulaName}(value1, [value2, ...])`;
        case 'AVERAGEA':
            return `${formulaName}(value1, [value2, ...])`;
        case 'AVERAGEIF':
            return `${formulaName}(range, criterion, [average_range])`;
        case 'AVERAGEIFS':
            return `${formulaName}(average_range, criteria_range1, criterion1, [criteria_range2, criterion2, ...])`;
        case 'BASE':
            return `${formulaName}(number, radix, [min_length])`;
        case 'BESSELI':
            return `${formulaName}(x, n)`;
        case 'BESSELJ':
            return `${formulaName}(x, n)`;
        case 'BESSELK':
            return `${formulaName}(x, n)`;
        case 'BESSELY':
            return `${formulaName}(x, n)`;
        case 'BETA.DIST':
            return `${formulaName}(x, alpha, beta, [cumulative], [lower_bound], [upper_bound])`;
        case 'BETA.INV':
            return `${formulaName}(probability, alpha, beta, [lower_bound], [upper_bound])`;
        case 'BETADIST':
            return `${formulaName}(x, alpha, beta, [cumulative], [lower_bound], [upper_bound])`;
        case 'BETAINV':
            return `${formulaName}(probability, alpha, beta, [lower_bound], [upper_bound])`;
        case 'BIN2DEC':
            return `${formulaName}(binary_number)`;
        case 'BIN2HEX':
            return `${formulaName}(binary_number, [places])`;
        case 'BIN2OCT':
            return `${formulaName}(binary_number, [places])`;
        case 'BINOM.DIST':
            return `${formulaName}(x, trials, probability, [cumulative])`;
        case 'BINOM.DIST.RANGE':
            return `${formulaName}(trials, probability_s, number_s, [number_s2])`;
        case 'BINOM.INV':
            return `${formulaName}(trials, probability, alpha)`;
        case 'BINOMDIST':
            return `${formulaName}(x, trials, probability, [cumulative])`;
        case 'BITAND':
            return `${formulaName}(number1, number2)`;
        case 'BITLSHIFT':
            return `${formulaName}(number, shift_amount)`;
        case 'BITOR':
            return `${formulaName}(number1, number2)`;
        case 'BITRSHIFT':
            return `${formulaName}(number, shift_amount)`;
        case 'BITXOR':
            return `${formulaName}(number1, number2)`;
        case 'CEILING':
            return `${formulaName}(number, [significance])`;
        case 'CEILINGMATH':
            return `${formulaName}(number, [significance], [mode])`;
        case 'CEILINGPRECISE':
            return `${formulaName}(number, [significance])`;
        case 'CHAR':
            return `${formulaName}(number)`;
        case 'CHISQ.DIST':
            return `${formulaName}(x, degrees_freedom, [cumulative])`;
        case 'CHISQ.DIST.RT':
            return `${formulaName}(x, degrees_freedom)`;
        case 'CHISQ.INV':
            return `${formulaName}(probability, degrees_freedom)`;
        case 'CHISQ.INV.RT':
            return `${formulaName}(probability, degrees_freedom)`;
        case 'CHOOSE':
            return `${formulaName}(index, value1, [value2, ...])`;
        case 'CLEAN':
            return `${formulaName}(text)`;
        case 'CODE':
            return `${formulaName}(text)`;
        case 'COLUMN':
            return `${formulaName}([reference])`;
        case 'COLUMNS':
            return `${formulaName}(array)`;
        case 'COMBIN':
            return `${formulaName}(number, number_chosen)`;
        case 'COMBINA':
            return `${formulaName}(number, number_chosen)`;
        case 'COMPLEX':
            return `${formulaName}(real_num, [i_num], [suffix])`;
        case 'CONCATENATE':
            return `${formulaName}(text1, [text2, ...])`;
        case 'CONFIDENCE':
            return `${formulaName}(alpha, standard_deviation, size)`;
        case 'CONFIDENCE.NORM':
            return `${formulaName}(alpha, standard_deviation, size)`;
        case 'CONFIDENCE.T':
            return `${formulaName}(alpha, standard_deviation, size)`;
        case 'CONVERT':
            return `${formulaName}(number, from_unit, to_unit)`;
        case 'CORREL':
            return `${formulaName}(array1, array2)`;
        case 'COS':
            return `${formulaName}(angle)`;
        case 'COSH':
            return `${formulaName}(angle)`;
        case 'COT':
            return `${formulaName}(angle)`;
        case 'COTH':
            return `${formulaName}(angle)`;
        case 'COUNT':
            return `${formulaName}(value1, [value2, ...])`;
        case 'COUNTA':
            return `${formulaName}(value1, [value2, ...])`;
        case 'COUNTBLANK':
            return `${formulaName}(range)`;
        case 'COUNTIF':
            return `${formulaName}(range, criterion)`;
        case 'COUNTIFS':
            return `${formulaName}(criteria_range1, criterion1, [criteria_range2, criterion2, ...])`;
        case 'COUNTIN':
            return `${formulaName}(range, value)`;
        case 'COUNTUNIQUE':
            return `${formulaName}(value1, [value2, ...])`;
        case 'COVARIANCE.P':
            return `${formulaName}(array1, array2)`;
        case 'COVARIANCE.S':
            return `${formulaName}(array1, array2)`;
        case 'CSC':
            return `${formulaName}(angle)`;
        case 'CSCH':
            return `${formulaName}(angle)`;
        case 'CUMIPMT':
            return `${formulaName}(rate, periods, value, start_period, end_period, type)`;
        case 'CUMPRINC':
            return `${formulaName}(rate, periods, value, start_period, end_period, type)`;
        case 'DATE':
            return `${formulaName}(year, month, day)`;
        case 'DATEVALUE':
            return `${formulaName}(date_text)`;
        case 'DAY':
            return `${formulaName}(date)`;
        case 'DAYS':
            return `${formulaName}(end_date, start_date)`;
        case 'DAYS360':
            return `${formulaName}(start_date, end_date, [method])`;
        case 'DB':
            return `${formulaName}(cost, salvage, life, period, [month])`;
        case 'DDB':
            return `${formulaName}(cost, salvage, life, period, [factor])`;
        case 'DEC2BIN':
            return `${formulaName}(number, [places])`;
        case 'DEC2HEX':
            return `${formulaName}(number, [places])`;
        case 'DEC2OCT':
            return `${formulaName}(number, [places])`;
        case 'DECIMAL':
            return `${formulaName}(text, radix)`;
        case 'DEGREES':
            return `${formulaName}(angle)`;
        case 'DELTA':
            return `${formulaName}(number1, [number2])`;
        case 'DEVSQ':
            return `${formulaName}(value1, [value2, ...])`;
        case 'DIVIDE':
            return `${formulaName}(dividend, divisor)`;
        case 'DOLLARDE':
            return `${formulaName}(text, [fraction])`;
        case 'DOLLARFR':
            return `${formulaName}(text, [fraction])`;
        case 'E':
            return `${formulaName}()`;
        case 'EDATE':
            return `${formulaName}(start_date, months)`;
        case 'EFFECT':
            return `${formulaName}(annual_nominal_rate, npery)`;
        case 'EOMONTH':
            return `${formulaName}(start_date, months)`;
        case 'EQ':
            return `${formulaName}(value1, value2)`;
        case 'ERF':
            return `${formulaName}(lower_bound, [upper_bound])`;
        case 'ERFC':
            return `${formulaName}(lower_bound)`;
        case 'EVEN':
            return `${formulaName}(number)`;
        case 'EXACT':
            return `${formulaName}(text1, text2)`;
        case 'EXP':
            return `${formulaName}(number)`;
        case 'EXPON.DIST':
            return `${formulaName}(x, lambda, [cumulative])`;
        case 'EXPONDIST':
            return `${formulaName}(x, lambda, [cumulative])`;
        case 'F.DIST':
            return `${formulaName}(x, degrees_freedom1, degrees_freedom2, [cumulative])`;
        case 'F.DIST.RT':
            return `${formulaName}(x, degrees_freedom1, degrees_freedom2)`;
        case 'F.INV':
            return `${formulaName}(probability, degrees_freedom1, degrees_freedom2)`;
        case 'F.INV.RT':
            return `${formulaName}(probability, degrees_freedom1, degrees_freedom2)`;
        case 'FACT':
            return `${formulaName}(number)`;
        case 'FACTDOUBLE':
            return `${formulaName}(number)`;
        case 'FALSE':
            return `${formulaName}()`;
        case 'FDIST':
            return `${formulaName}(x, degrees_freedom1, degrees_freedom2, [cumulative])`;
        case 'FDISTRT':
            return `${formulaName}(x, degrees_freedom1, degrees_freedom2)`;
        case 'FIND':
            return `${formulaName}(find_text, within_text, [start_num])`;
        case 'FINV':
            return `${formulaName}(probability, degrees_freedom1, degrees_freedom2)`;
        case 'FINVRT':
            return `${formulaName}(probability, degrees_freedom1, degrees_freedom2)`;
        case 'FISHER':
            return `${formulaName}(x)`;
        case 'FISHERINV':
            return `${formulaName}(y)`;
        case 'FLATTEN':
            return `${formulaName}(array_or_range)`;
        case 'FLOOR':
            return `${formulaName}(number, [significance])`;
        case 'FORECAST':
            return `${formulaName}(x, data_y, data_x)`;
        case 'FREQUENCY':
            return `${formulaName}(data, bins)`;
        case 'FV':
            return `${formulaName}(rate, nper, pmt, [pv], [type])`;
        case 'FVSCHEDULE':
            return `${formulaName}(principal, schedule)`;
        case 'GAMMA':
            return `${formulaName}(number)`;
        case 'GAMMA.DIST':
            return `${formulaName}(x, alpha, beta, [cumulative])`;
        case 'GAMMA.INV':
            return `${formulaName}(probability, alpha, beta)`;
        case 'GAMMADIST':
            return `${formulaName}(x, alpha, beta, [cumulative])`;
        case 'GAMMAINV':
            return `${formulaName}(probability, alpha, beta)`;
        case 'GAMMALN':
            return `${formulaName}(number)`;
        case 'GAMMALN.PRECISE':
            return `${formulaName}(number)`;
        case 'GAUSS':
            return `${formulaName}(z)`;
        case 'GCD':
            return `${formulaName}(number1, [number2, ...])`;
        case 'GEOMEAN':
            return `${formulaName}(value1, [value2, ...])`;
        case 'GESTEP':
            return `${formulaName}(number, step_value)`;
        case 'GROWTH':
            return `${formulaName}(known_y, [known_x], [new_x], [const])`;
        case 'GTE':
            return `${formulaName}(value1, value2)`;
        case 'HARMEAN':
            return `${formulaName}(value1, [value2, ...])`;
        case 'HEX2BIN':
            return `${formulaName}(hexadecimal_number, [places])`;
        case 'HEX2DEC':
            return `${formulaName}(hexadecimal_number)`;
        case 'HEX2OCT':
            return `${formulaName}(hexadecimal_number, [places])`;
        case 'HOUR':
            return `${formulaName}(time)`;
        case 'HTML2TEXT':
            return `${formulaName}(html_text)`;
        case 'HYPGEOM.DIST':
            return `${formulaName}(x, population_size, sample_size, number_s)`;
        case 'HYPGEOMDIST':
            return `${formulaName}(x, population_size, sample_size, number_s)`;
        case 'IF':
            return `${formulaName}(logical_expression, value_if_true, value_if_false)`;
        case 'IMABS':
            return `${formulaName}(inumber)`;
        case 'IMAGINARY':
            return `${formulaName}(inumber)`;
        case 'IMARGUMENT':
            return `${formulaName}(inumber)`;
        case 'IMCONJUGATE':
            return `${formulaName}(inumber)`;
        case 'IMCOS':
            return `${formulaName}(inumber)`;
        case 'IMCOSH':
            return `${formulaName}(inumber)`;
        case 'IMCOT':
            return `${formulaName}(inumber)`;
        case 'IMCSC':
            return `${formulaName}(inumber)`;
        case 'IMCSCH':
            return `${formulaName}(inumber)`;
        case 'IMDIV':
            return `${formulaName}(inumber1, inumber2)`;
        case 'IMEXP':
            return `${formulaName}(inumber)`;
        case 'IMLN':
            return `${formulaName}(inumber)`;
        case 'IMLOG10':
            return `${formulaName}(inumber)`;
        case 'IMLOG2':
            return `${formulaName}(inumber)`;
        case 'IMPOWER':
            return `${formulaName}(inumber, number)`;
        case 'IMPRODUCT':
            return `${formulaName}(inumber1, [inumber2, ...])`;
        case 'IMREAL':
            return `${formulaName}(inumber)`;
        case 'IMSEC':
            return `${formulaName}(inumber)`;
        case 'IMSECH':
            return `${formulaName}(inumber)`;
        case 'IMSIN':
            return `${formulaName}(inumber)`;
        case 'IMSINH':
            return `${formulaName}(inumber)`;
        case 'IMSQRT':
            return `${formulaName}(inumber)`;
        case 'IMSUB':
            return `${formulaName}(inumber1, inumber2)`;
        case 'IMSUM':
            return `${formulaName}(inumber1, [inumber2, ...])`;
        case 'IMTAN':
            return `${formulaName}(inumber)`;
        case 'INT':
            return `${formulaName}(number)`;
        case 'INTERCEPT':
            return `${formulaName}(known_y, known_x)`;
        case 'INTERVAL':
            return `${formulaName}(start, end, [step])`;
        case 'IPMT':
            return `${formulaName}(rate, period, periods, present_value, [future_value], [type])`;
        case 'IRR':
            return `${formulaName}(values, [guess])`;
        case 'ISBINARY':
            return `${formulaName}(number)`;
        case 'ISBLANK':
            return `${formulaName}(value)`;
        case 'ISEVEN':
            return `${formulaName}(number)`;
        case 'ISLOGICAL':
            return `${formulaName}(value)`;
        case 'ISNONTEXT':
            return `${formulaName}(value)`;
        case 'ISNUMBER':
            return `${formulaName}(value)`;
        case 'ISODD':
            return `${formulaName}(number)`;
        case 'ISOWEEKNUM':
            return `${formulaName}(date)`;
        case 'ISPMT':
            return `${formulaName}(rate, period, periods, present_value)`;
        case 'ISTEXT':
            return `${formulaName}(value)`;
        case 'JOIN':
            return `${formulaName}(delimiter, string1, [string2, …])`;
        case 'KURT':
            return `${formulaName}(value1, [value2, ...])`;
        case 'LARGE':
            return `${formulaName}(array, k)`;
        case 'LCM':
            return `${formulaName}(number1, [number2, …])`;
        case 'LEFT':
            return `${formulaName}(text, [num_chars])`;
        case 'LEN':
            return `${formulaName}(text)`;
        case 'LINEST':
            return `${formulaName}(known_y, [known_x], [const], [stats])`;
        case 'LN':
            return `${formulaName}(number)`;
        case 'LOG':
            return `${formulaName}(number, [base])`;
        case 'LOG10':
            return `${formulaName}(number)`;
        case 'LOGEST':
            return `${formulaName}(known_y, [known_x], [const], [stats])`;
        case 'LOGNORM.DIST':
            return `${formulaName}(x, mean, standard_dev, [cumulative])`;
        case 'LOGNORM.INV':
            return `${formulaName}(probability, mean, standard_dev)`;
        case 'LOGNORMDIST':
            return `${formulaName}(x, mean, standard_dev, [cumulative])`;
        case 'LOGNORMINV':
            return `${formulaName}(probability, mean, standard_dev)`;
        case 'LOWER':
            return `${formulaName}(text)`;
        case 'LT':
            return `${formulaName}(value1, value2)`;
        case 'LTE':
            return `${formulaName}(value1, value2)`;
        case 'MATCH':
            return `${formulaName}(lookup_value, lookup_array, [match_type])`;
        case 'MAX':
            return `${formulaName}(value1, [value2, …])`;
        case 'MAXA':
            return `${formulaName}(value1, [value2, …])`;
        case 'MEDIAN':
            return `${formulaName}(value1, [value2, …])`;
        case 'MID':
            return `${formulaName}(text, start_num, num_chars)`;
        case 'MIN':
            return `${formulaName}(value1, [value2, …])`;
        case 'MINA':
            return `${formulaName}(value1, [value2, …])`;
        case 'MINUS':
            return `${formulaName}(value1, value2)`;
        case 'MINUTE':
            return `${formulaName}(time)`;
        case 'MIRR':
            return `${formulaName}(values, finance_rate, reinvest_rate)`;
        case 'MOD':
            return `${formulaName}(number, divisor)`;
        case 'MODE.MULT':
            return `${formulaName}(value1, [value2, …])`;
        case 'MODE.SNGL':
            return `${formulaName}(value1, [value2, …])`;
        case 'MODEMULT':
            return `${formulaName}(value1, [value2, …])`;
        case 'MODESNGL':
            return `${formulaName}(value1, [value2, …])`;
        case 'MONTH':
            return `${formulaName}(date)`;
        case 'MROUND':
            return `${formulaName}(number, multiple)`;
        case 'MULTINOMIAL':
            return `${formulaName}(number1, [number2, …])`;
        case 'MULTIPLY':
            return `${formulaName}(value1, value2, …)`;
        case 'NE':
            return `${formulaName}(value1, value2)`;
        case 'NEGBINOM.DIST':
            return `${formulaName}(number_f, number_s, probability_s, [cumulative])`;
        case 'NEGBINOMDIST':
            return `${formulaName}(number_f, number_s, probability_s, [cumulative])`;
        case 'NETWORKDAYS':
            return `${formulaName}(start_date, end_date, [holidays])`;
        case 'NOMINAL':
            return `${formulaName}(effect_rate, npery)`;
        case 'NORM.DIST':
            return `${formulaName}(x, mean, standard_dev, [cumulative])`;
        case 'NORM.INV':
            return `${formulaName}(probability, mean, standard_dev)`;
        case 'NORM.S.DIST':
            return `${formulaName}(z, [cumulative])`;
        case 'NORM.S.INV':
            return `${formulaName}(probability)`;
        case 'NORMDIST':
            return `${formulaName}(x, mean, standard_dev, [cumulative])`;
        case 'NORMINV':
            return `${formulaName}(probability, mean, standard_dev)`;
        case 'NORMSDIST':
            return `${formulaName}(z)`;
        case 'NORMSINV':
            return `${formulaName}(probability)`;
        case 'NOT':
            return `${formulaName}(logical)`;
        case 'NOW':
            return `${formulaName}()`;
        case 'NPER':
            return `${formulaName}(rate, pmt, pv, [fv], [type])`;
        case 'NPV':
            return `${formulaName}(rate, value1, [value2, …])`;
        case 'NUMBERS':
            return `${formulaName}(number1, [number2, …])`;
        case 'OCT2BIN':
            return `${formulaName}(octal_number, [places])`;
        case 'OCT2DEC':
            return `${formulaName}(octal_number)`;
        case 'OCT2HEX':
            return `${formulaName}(octal_number, [places])`;
        case 'ODD':
            return `${formulaName}(number)`;
        case 'OR':
            return `${formulaName}(logical1, [logical2, …])`;
        case 'PDURATION':
            return `${formulaName}(rate, pv, fv)`;
        case 'PEARSON':
            return `${formulaName}(array1, array2)`;
        case 'PERCENTILEEXC':
            return `${formulaName}(array, k)`;
        case 'PERCENTILEINC':
            return `${formulaName}(array, k)`;
        case 'PERCENTRANKEXC':
            return `${formulaName}(array, x, [significance])`;
        case 'PERCENTRANKINC':
            return `${formulaName}(array, x, [significance])`;
        case 'PERMUT':
            return `${formulaName}(number, number_chosen)`;
        case 'PERMUTATIONA':
            return `${formulaName}(number, number_chosen)`;
        case 'PHI':
            return `${formulaName}(z)`;
        case 'PI':
            return `${formulaName}()`;
        case 'PMT':
            return `${formulaName}(rate, nper, pv, [fv], [type])`;
        case 'POISSON.DIST':
            return `${formulaName}(x, mean, [cumulative])`;
        case 'POISSONDIST':
            return `${formulaName}(x, mean, [cumulative])`;
        case 'POW':
            return `${formulaName}(number, power)`;
        case 'POWER':
            return `${formulaName}(number, power)`;
        case 'PPMT':
            return `${formulaName}(rate, per, nper, pv, [fv], [type])`;
        case 'PROB':
            return `${formulaName}(x_range, probability_range, [lower_bound], [upper_bound])`;
        case 'PRODUCT':
            return `${formulaName}(number1, [number2, …])`;
        case 'PROPER':
            return `${formulaName}(text)`;
        case 'PV':
            return `${formulaName}(rate, nper, pmt, [fv], [type])`;
        case 'QUARTILE.EXC':
            return `${formulaName}(array, quart)`;
        case 'QUARTILE.INC':
            return `${formulaName}(array, quart)`;
        case 'QUARTILEEXC':
            return `${formulaName}(array, quart)`;
        case 'QUARTILEINC':
            return `${formulaName}(array, quart)`;
        case 'QUOTIENT':
            return `${formulaName}(numerator, denominator)`;
        case 'RADIANS':
            return `${formulaName}(angle)`;
        case 'RAND':
            return `${formulaName}()`;
        case 'RANDBETWEEN':
            return `${formulaName}(bottom, top)`;
        case 'RANK.AVG':
            return `${formulaName}(number, ref, [order])`;
        case 'RANK.EQ':
            return `${formulaName}(number, ref, [order])`;
        case 'RANKAVG':
            return `${formulaName}(number, ref, [order])`;
        case 'RANKEQ':
            return `${formulaName}(number, ref, [order])`;
        case 'RATE':
            return `${formulaName}(nper, pmt, pv, [fv], [type], [guess])`;
        case 'REFERENCE':
            return `${formulaName}(reference)`;
        case 'REGEXEXTRACT':
            return `${formulaName}(text, regular_expression)`;
        case 'REGEXMATCH':
            return `${formulaName}(text, regular_expression)`;
        case 'REGEXREPLACE':
            return `${formulaName}(text, regular_expression, replacement)`;
        case 'REPLACE':
            return `${formulaName}(old_text, start_num, num_chars, new_text)`;
        case 'REPT':
            return `${formulaName}(text, number_times)`;
        case 'RIGHT':
            return `${formulaName}(text, num_chars)`;
        case 'ROMAN':
            return `${formulaName}(number, [form])`;
        case 'ROUND':
            return `${formulaName}(number, num_digits)`;
        case 'ROUNDDOWN':
            return `${formulaName}(number, num_digits)`;
        case 'ROUNDUP':
            return `${formulaName}(number, num_digits)`;
        case 'ROW':
            return `${formulaName}([reference])`;
        case 'ROWS':
            return `${formulaName}(array)`;
        case 'RRI':
            return `${formulaName}(nper, pv, fv)`;
        case 'RSQ':
            return `${formulaName}(known_y, known_x)`;
        case 'SEARCH':
            return `${formulaName}(find_text, within_text, [start_num])`;
        case 'SEC':
            return `${formulaName}(angle)`;
        case 'SECH':
            return `${formulaName}(angle)`;
        case 'SECOND':
            return `${formulaName}(serial_number)`;
        case 'SERIESSUM':
            return `${formulaName}(x, n, m, coefficients)`;
        case 'SIGN':
            return `${formulaName}(number)`;
        case 'SIN':
            return `${formulaName}(angle)`;
        case 'SINH':
            return `${formulaName}(angle)`;
        case 'SKEW':
            return `${formulaName}(number1, [number2, ...])`;
        case 'SKEW.P':
            return `${formulaName}(number1, [number2, ...])`;
        case 'SKEWP':
            return `${formulaName}(number1, [number2, ...])`;
        case 'SLN':
            return `${formulaName}(cost, salvage, life)`;
        case 'SLOPE':
            return `${formulaName}(known_y, known_x)`;
        case 'SMALL':
            return `${formulaName}(array, k)`;
        case 'SPLIT':
            return `${formulaName}(text, delimiter)`;
        case 'SQRT':
            return `${formulaName}(number)`;
        case 'SQRTPI':
            return `${formulaName}(number)`;
        case 'STANDARDIZE':
            return `${formulaName}(x, mean, standard_dev)`;
        case 'STDEV.P':
            return `${formulaName}(value1, [value2, …])`;
        case 'STDEV.S':
            return `${formulaName}(value1, [value2, …])`;
        case 'STDEVA':
            return `${formulaName}(value1, [value2, …])`;
        case 'STDEVP':
            return `${formulaName}(value1, [value2, …])`;
        case 'STDEVPA':
            return `${formulaName}(value1, [value2, …])`;
        case 'STDEVS':
            return `${formulaName}(value1, [value2, …])`;
        case 'STEYX':
            return `${formulaName}(known_y, known_x)`;
        case 'SUBSTITUTE':
            return `${formulaName}(text, old_text, new_text, [instance_num])`;
        case 'SUBTOTAL':
            return `${formulaName}(function_code, ref1, [ref2, …])`;
        case 'SUM':
            return `${formulaName}(value1, [value2, …])`;
        case 'SUMIF':
            return `${formulaName}(range, criteria, [sum_range])`;
        case 'SUMIFS':
            return `${formulaName}(sum_range, criteria_range1, criterion1, [criteria_range2, criterion2, …])`;
        case 'SUMPRODUCT':
            return `${formulaName}(array1, [array2, …])`;
        case 'SUMSQ':
            return `${formulaName}(value1, [value2, …])`;
        case 'SUMX2MY2':
            return `${formulaName}(array_x, array_y)`;
        case 'SUMX2PY2':
            return `${formulaName}(array_x, array_y)`;
        case 'SUMXMY2':
            return `${formulaName}(array_x, array_y)`;
        case 'SWITCH':
            return `${formulaName}(expression, value1, result1, [default, result_default])`;
        case 'SYD':
            return `${formulaName}(cost, salvage, life, period)`;
        case 'T':
            return `${formulaName}(value)`;
        case 'T.DIST':
            return `${formulaName}(x, degrees_freedom, [cumulative])`;
        case 'T.DIST.2T':
            return `${formulaName}(x, degrees_freedom)`;
        case 'T.DIST.RT':
            return `${formulaName}(x, degrees_freedom)`;
        case 'T.INV':
            return `${formulaName}(probability, degrees_freedom)`;
        case 'T.INV.2T':
            return `${formulaName}(probability, degrees_freedom)`;
        case 'TAN':
            return `${formulaName}(angle)`;
        case 'TANH':
            return `${formulaName}(angle)`;
        case 'TBILLEQ':
            return `${formulaName}(settlement, maturity, discount)`;
        case 'TBILLPRICE':
            return `${formulaName}(settlement, maturity, discount)`;
        case 'TBILLYIELD':
            return `${formulaName}(settlement, maturity, price)`;
        case 'TDIST':
            return `${formulaName}(x, degrees_freedom, [tails], [type])`;
        case 'TDIST2T':
            return `${formulaName}(x, degrees_freedom)`;
        case 'TDISTRT':
            return `${formulaName}(x, degrees_freedom)`;
        case 'TIME':
            return `${formulaName}(hour, minute, second)`;
        case 'TIMEVALUE':
            return `${formulaName}(time_text)`;
        case 'TINV':
            return `${formulaName}(probability, degrees_freedom)`;
        case 'TINV2T':
            return `${formulaName}(probability, degrees_freedom)`;
        case 'TODAY':
            return `${formulaName}()`;
        case 'TRANSPOSE':
            return `${formulaName}(array_or_range)`;
        case 'TREND':
            return `${formulaName}(known_y, [known_x], [new_x], [const])`;
        case 'TRIM':
            return `${formulaName}(text)`;
        case 'TRIMMEAN':
            return `${formulaName}(data, exclude_proportion)`;
        case 'TRUE':
            return `${formulaName}()`;
        case 'TRUNC':
            return `${formulaName}(number, [num_digits])`;
        case 'UNICHAR':
            return `${formulaName}(number)`;
        case 'UNICODE':
            return `${formulaName}(text)`;
        case 'UNIQUE':
            return `${formulaName}(array, [by_col], [occurs_once])`;
        case 'UPPER':
            return `${formulaName}(text)`;
        case 'VAR.P':
            return `${formulaName}(value1, [value2, …])`;
        case 'VAR.S':
            return `${formulaName}(value1, [value2, …])`;
        case 'VARA':
            return `${formulaName}(value1, [value2, …])`;
        case 'VARP':
            return `${formulaName}(value1, [value2, …])`;
        case 'VARPA':
            return `${formulaName}(value1, [value2, …])`;
        case 'VARS':
            return `${formulaName}(value1, [value2, …])`;
        case 'WEEKDAY':
            return `${formulaName}(serial_number, [return_type])`;
        case 'WEEKNUM':
            return `${formulaName}(serial_number, [return_type])`;
        case 'WEIBULL.DIST':
            return `${formulaName}(x, alpha, beta, [cumulative])`;
        case 'WEIBULLDIST':
            return `${formulaName}(x, alpha, beta, [cumulative])`;
        case 'WORKDAY':
            return `${formulaName}(start_date, days, [holidays])`;
        case 'XIRR':
            return `${formulaName}(values, dates, [guess])`;
        case 'XNPV':
            return `${formulaName}(rate, values, dates)`;
        case 'XOR':
            return `${formulaName}(logical1, [logical2, …])`;
        case 'YEAR':
            return `${formulaName}(serial_number)`;
        case 'YEARFRAC':
            return `${formulaName}(start_date, end_date, [basis])`;
        case 'VLOOKUP':
            return `${formulaName}(search_key, range, index, [is_sorted])`;
        case 'HLOOKUP':
            return `${formulaName}(search_key, range, index, [is_sorted])`;
        default:
        // return 'No description available.';
    }
}
