import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { SpreadSheetCtxProvider } from "./context/spreadsheet-context";
import { SpreadSheetCtxProviderRd } from "./context/spreadsheet-context-rd";
import 'antd/dist/antd.css';
import { DocumentInfoProvider } from "./Pages/SpreadsheetContainer/documentInfoContex";

ReactDOM.render(
  <React.StrictMode>
    <DocumentInfoProvider>
      <SpreadSheetCtxProvider>
        <SpreadSheetCtxProviderRd>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SpreadSheetCtxProviderRd>
      </SpreadSheetCtxProvider>
    </DocumentInfoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
