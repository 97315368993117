export class UserSelectorRegistry {
  userSelectorInstanceObj = {};

  constructor() {
    this.userReg = null;
  }

  setUserInstanceToRegistry = (id, instance) => {
    this.userSelectorInstanceObj[id] = instance;
    // console.log(this.userSelectorInstanceObj);
  };

  updateUserSelectorPosition = (id,height,width,top,left) => {
      const userSelector = this.userSelectorInstanceObj[id];
      if (userSelector) {
        userSelector.updatePositions(height,width,top,left);
      }
  }

  deleteUserSelector = (id) => {
    const userSelector = this.userSelectorInstanceObj[id];
    userSelector?.deleteSelector();
  }
  
  updateMultipleSelection = (id,data) => {
    const userSelector = this.userSelectorInstanceObj[id];
    userSelector.updateMultipleSelection();
  }

  hideUserSelector = (id) => {
    const userSelector = this.userSelectorInstanceObj[id];
    userSelector?.hide();
  }
}


