import { h } from './element';
import { cssPrefix } from '../config';
import { chartTypes } from '../core/charts';
import Icon from './icon';
import Dropdown from './dropdown';
import { ChatBox } from '../../../components/ChatBox/ChatBox';

export default class DropdownCharts extends Dropdown {
  constructor() {
    const chartItems = chartTypes.map((it) => {
      const item = h('div', `${cssPrefix}-item`);
      item.css('margin-bottom', '8px');
      const content = h('div', 'content');
      content.css({ 'display': 'flex', 'justify-content': 'space-between', 'align-items': 'center' });
      const imageDiv = h('div', 'image-div');
      imageDiv.css({ 'width': '30px', 'margin-top': '-10px' });
      const image = h('img', 'chart-image');
      image.attr('src', it.image);
      image.css({ 'width': '100%', 'height': '100%' });
      const name = h('div', 'chart-name');
      name.html(it.title());
      imageDiv.child(image);
      content.child(name);
      content.child(imageDiv);
      item.child(content);

      item.on('click', () => {
        this.hide();
        this.change(it);
      });
      const itElement = h('div', `${cssPrefix}-item`).html(it.title());
      item.child(itElement);
      return item;
    });
    super(new Icon('chart'), '180px', false, 'bottom-left', ...chartItems);
  }
}
