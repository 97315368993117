import React from 'react';
import { Skeleton, Avatar, Space } from 'antd';
import MyHeader from '../Header/Header.module.css';
import classesAvatar from '../Avatar/UserAvatar.module.css';
import MyNav from "../Navigations/Navigations.module.css";
import classes from '../NavTabs/navTabs.module.css';
import MyLogo from '../Logo/Logo.module.css';

const Loader = () => {
    const [sheetHeight, setsheetHeight] = React.useState(
        window.innerWidth >= 320 && window.innerWidth <= 480 ? '84vh' : '72vh'
      );
    
      React.useEffect(() => {
        const handleResize = () => {
            setsheetHeight(
            window.innerWidth >= 320 && window.innerWidth <= 480 ? '84vh' : '72vh'
          );
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 
    return (
        <div className='App'>
            <div className={MyHeader.headerMain} style={{ backgroundColor: "#F4F4F4" }} >
                <Skeleton.Image className={MyLogo.logo} active style={{ height: '30px', width: '30px' }} />
                <div className={MyHeader.headerCol}>
                    <div className={MyNav.navigationmain} style={{ backgroundColor: "#F4F4F4" }}>
                        <Space>
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                        </Space>
                    </div>
                </div>
                <div className={MyHeader.titleBarButtons}>
                    {/* <Skeleton.Button active />
                    <Skeleton.Image active style={{ height: '30px', width: '30px' }} />
                    <Avatar.Group className={classesAvatar.avatar}>
                        <Skeleton.Avatar active />
                        <Skeleton.Avatar active />
                        <Skeleton.Avatar active />
                    </Avatar.Group> */}
                </div>
                <Skeleton.Image active style={{ height: '35px', width: '35px' }} />
            </div>
            <div id="spreadsheet-container" style={{ marginTop: '5px', backgroundColor: "#F4F4F4" }}>
                <div className='x-spreadsheet'>
                    <div className='x-spreadsheet-toolbar' style={{ backgroundColor: "#F4F4F4" }}>
                        <div className='x-spreadsheet-toolbar-btns'>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <div className='x-spreadsheet-toolbar-divider'></div>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <div className='x-spreadsheet-toolbar-divider'></div>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <div className='x-spreadsheet-toolbar-divider'></div>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            {/* <div className='x-spreadsheet-toolbar-divider'></div>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <div className='x-spreadsheet-toolbar-divider'></div>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <Skeleton.Button className='x-spreadsheet-toolbar-btn' active style={{ width: '100%', height: '100%' }}></Skeleton.Button>
                            <div className='x-spreadsheet-toolbar-divider'></div> */}
                        </div>
                    </div>
                    <div className='x-spreadsheet-cell-input-container' style={{ marginTop: '2px' }}>
                        <div className='x-spreadsheet-cell-select'>
                        </div>
                        <div className='x-spreadsheet-toolbar-divider' style={{marginTop:'5px'}}></div>
                        <div className='x-spreadsheet-input-label'></div>
                        <div className='x-spreadsheet-toolbar-divider' style={{marginTop:'5px'}}></div>
                        <div className='x-spreadsheet-cell-input'></div>
                    </div>
                    <div className='x-spreadsheet-sheet'>
                        {/* <div className='x-spreadsheet-table'> */}
                        <Skeleton.Button className='x-spreadsheet-sheet' active style={{height:sheetHeight,width: '100vw'}}></Skeleton.Button>

                        {/* </div> */}
                    </div>

                    <Skeleton.Button className='x-spreadsheet-toolbar-btn active' active style={{ width: '100vw', height: '6.8vh' }}></Skeleton.Button>

                </div>
            </div>
        </div>

    )
}

export default Loader;
