import React, { useState } from "react";
// import { Dropdown } from "antd";
import { Menu, Dropdown, Modal, Form, Radio, Input } from "antd";
import classes from "./navTabs.module.css";
// import SubMenu from "antd/lib/menu/SubMenu";
import { MenuItem } from "rc-menu";
import { useSpreadSheet } from "../../context/spreadsheet-context";
import SubMenu from "antd/lib/menu/SubMenu";
import CreateForm from "../PivotTable/form";
import EditPivotTable from "../PivotTable/editTable";
import { EditFilled } from "@ant-design/icons";
import { findSheetName, getSelectedData } from "../../utils/helper";
import { loadSpreadsheetData, updateSheet } from "../../utils/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const InsertTab = () => {
  const isMobile = window.screen.width < 769;

  // const passFunc = () => console.log('CLICKED');
  const { spreadsheetInstanceRef } = useSpreadSheet();
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(1);
  const [isEditPivotTable, setIsEditPivotTable] = useState(
    spreadsheetInstanceRef?.current?.sheet?.data?.pivotData?.isEdit || false);
  const { id } = useParams();

  const forInsert = (type) => {
    const { sheet } = spreadsheetInstanceRef.current;
    sheet.data.insert(type);
    sheet.table.render();
    // console.log(sheet);
  };

  const forDelete = (type) => {
    const { sheet } = spreadsheetInstanceRef.current;
    if (type === "delete-cell") {
      sheet.data.deleteCell();
      sheet.table.render();
    } else if (type === "text") {
      sheet.data.deleteCell(type);
      sheet.table.render();
    } else if (type === "format") {
      sheet.data.deleteCell(type);
      sheet.table.render();
    } else {
      sheet.data.delete(type);
      sheet.table.render();
      // console.log(sheet);
    }
  };

  const openCreateFormModal = () => {
    setIsVisible(true);
  };
  const openTemplateScreen = async () => {
    const { sheet } = spreadsheetInstanceRef.current;
    let sheetsData = await loadSpreadsheetData(id);
    let data = getSelectedData(sheet, sheet.data.selector.range);
    let sheetName = findSheetName(sheetsData, 'Template');
    const newSheetData = spreadsheetInstanceRef.current.addSheet(
      sheetName,
      true
    );
    let emails = [];
    // data.data.forEach((item) => {
    //   if (item[0].toLowerCase().includes('email')) {
    //     emails.push(item);
    //   }
    // })
    data.data.forEach((subArray) => {
      if (subArray[0].toLowerCase().includes('email')) {
        subArray.slice(1).forEach((subValue) => {
          if (subValue != '') {
            emails.push(subValue);
          }
        })
      }
    });
    const templateData = {
      ...data,
      isTemplate: true,
      emails: emails,
      editorContent: '',
      isEmailTemplate:false,
    }
    newSheetData.templateSheet = templateData;
    sheet.resetData(newSheetData, 'new');
    let payload = newSheetData.getData();
    payload.templateSheet = templateData;
    updateSheet(id, payload);
  }
  const menu = (
    <Menu>
      <MenuItem key="sub10" onClick={() => forInsert("row")}>
        <div>Insert Row</div>
      </MenuItem>
      <MenuItem key="sub11" onClick={() => forInsert("column")}>
        <div>Insert Column</div>
      </MenuItem>
      <MenuItem key="sub-piviot" onClick={(e) => openCreateFormModal(e)}>
        <div>Pivot table</div>
      </MenuItem>
      <div class="nav-tab-divider"></div>
      <MenuItem key="sub12.2" onClick={() => forDelete("row")}>
        <div>Delete row</div>
      </MenuItem>
      <MenuItem key="sub12.3" onClick={() => forDelete("column")}>
        <div>Delete column</div>
      </MenuItem>
      <div class="nav-tab-divider"></div>
      <SubMenu key="sub13" title="Delete with">
        <MenuItem key="sub13.1" onClick={() => forDelete("text")}>
          <div>Delete text</div>
        </MenuItem>
        <MenuItem key="sub13.2" onClick={() => forDelete("format")}>
          <div>Delete format</div>
        </MenuItem>
      </SubMenu>
      <MenuItem key="sub14" onClick={(e) => openTemplateScreen(e)}>
        <div>Templates</div>
      </MenuItem>
    </Menu>
  );

  const handleEditTable = (e) => {
    e.preventDefault();
    const editIcon = document.getElementById("edit-pivot-table");
    if (editIcon) {
      editIcon.style.display = "none";
    }
    setIsEditPivotTable(true);
  }

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} arrow>
        <div
          className={classes.navTabButton}
          onClick={(e) => e.preventDefault()}
          style={{fontSize:isMobile?'11px':'14px'}}
        >
          Insert
        </div>
      </Dropdown>
      <span style={{ width: '0px' }}>
        {isVisible && (
          <CreateForm
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            count={count}
            setCount={setCount}
            isEditPivotTable={isEditPivotTable}
            setIsEditPivotTable={setIsEditPivotTable}
          />
        )}
      </span>
      {isEditPivotTable && (
        <EditPivotTable open={isEditPivotTable} setOpen={setIsEditPivotTable} />
      )}
      <div id='edit-pivot-table' style={{ position: 'absolute', bottom: '65px', left: '45px', zIndex: '999', display: 'none' }}>
        <EditFilled style={{ fontSize: '20px' }} onClick={(e) => handleEditTable(e)} />
      </div>
    </>
  );
};
