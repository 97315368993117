import React, { useRef, useState } from "react";
import Spreadsheet from "../x-data-spreadsheet";
import {
  emitSingularCellSelection,
   emitMultipleCellSelection,
  emitEditedCellData,
  // emitCellStyle,
  emitAddSheet,
  emitOnChanged,
} from "../socket";


const SpreadSheetContext = React.createContext({});

const config = {
  mode: "edit", // edit | read
  showToolbar: true,
  showGrid: true,
  showContextmenu: true,
  view: { 
    height: () => document.documentElement.clientHeight - (60 + 25),
    width: () => document.documentElement.clientWidth,
  },
  row: {
    len: 3000,
    height: 20, 
  },
  col: {
    len: 26,
    width: 100,
    indexWidth: 45,
    minWidth: 60,
  },
  style: {
    bgcolor: "#ffffff",
    align: "left",
    valign: "middle",
    textwrap: false,
    strike: false,
    underline: false,
    color: "#0a0a0a",
    font: {
      name: "Helvetica",
      size: 10,
      bold: false,
      italic: false,
    },
  },
};

export const SpreadSheetCtxProvider = ({ children }) => {
  let spreadsheetInstanceRef = useRef(null);
  const [ currPos, setCurrPos ] = useState({});

  const spreadSheet_init = () => {
    let spreadsheetInstance = new Spreadsheet("#spreadsheet-container", config);
    spreadsheetInstanceRef.current = spreadsheetInstance;
  };

  // const onChanged = () => {
  //   spreadsheetInstanceRef.current.on('change', (data) => {
  //     emitOnChanged(data);
  //   });
  // };

  const onCellSelected = (id) => {
    spreadsheetInstanceRef.current.on("cell-selected", (cell, ri, ci) => {
      // const oldData = spreadsheetInstanceRef.current.getData();
      // console.log(oldData);
      const ref = spreadsheetInstanceRef.current;
      // const refSheet = ref.sheet;
      // const initialIndex = refSheet.selector.indexes;
      // while (!(ref.dataQueue.isEmpty())) {
      //   const dataWaited = ref.dataQueue.dequeue();
      //   console.log(dataWaited);
      //   if (!(dataWaited === "Underflow")) {
      //     ref.loadData(dataWaited);
      //     const [ri, ci] = initialIndex;
      //     refSheet.selector.set(ri, ci);
      //   } else {
      //     ref.loadData(oldData);
      //     break;
      //   }
      // }
      // emitting sheet data
      const someData = ref.getData();
      // console.log(someData[0]);
      emitOnChanged(id, someData);
      // emitting selector data
      const { selector } = ref.sheet;
      emitSingularCellSelection({ id,selData: selector, cell: cell, rowIndex: ri, columnIndex: ci });
    });
  };

  const onCellsSelected = () => {
    spreadsheetInstanceRef.current.on(
      "cells-selected",
      (cell, { sri, sci, eri, eci }) => {
        const { selector } = spreadsheetInstanceRef.current.sheet;
        emitMultipleCellSelection({
          selsData: selector,
          cell: cell,
          startRowIndex: sri,
          startColumnIndex: sci,
          endRowIndex: eri,
          endColumnIndex: eci,
        });
      }
    );
  };

  const onCellEdited = (spreadsheetInstanceRef) => {
    spreadsheetInstanceRef.current.on("cell-edited", (text, ri, ci) => {
      const sheetsActive = spreadsheetInstanceRef.current.bottombar.activeEl;
      const sheetsAll = spreadsheetInstanceRef.current.bottombar.items;
      const index = sheetsAll.findIndex(it => it === sheetsActive);
      // console.log(index);
      emitEditedCellData({
        dataText: text,
        rowIndex: ri,
        columnIndex: ci,
        sheetIndex: index,
      });
    });
  };

  const onCellStyle = () => {
    spreadsheetInstanceRef.current.on("cell-style", (ri, ci) => {
      const cStyle = getCellStyle(ri, ci);
      console.log(cStyle, 'onCellStyle');
      // emitCellStyle({ rowIndex: ri, columnIndex: ci, cStyle: cStyle });
      // emitCellStyle({cStyle: cStyle})
    });
  };

  const onAddSheet = () => {
    spreadsheetInstanceRef.current.on("add-sheet", (datasLength) => {
      // console.log('onAddSheet', sheetIndex);
      emitAddSheet({ datasLength: datasLength });
    });
  };

  /*  const onUpdateCellText = () => {
    spreadsheetInstanceRef.current.on("update-cell-data", (text, ri, ci) => {
      emitOnChangeCellData({ dataText: text, rowIndex: ri, columnIndex: ci });
      spreadsheetInstanceRef.current
      .cellText(cellRowIndex, cellColumnIndex, cellText)
      .reRender();
    });
  }; */

  // const getCell = (ri, ci) => {
  //   const thisCell = spreadsheetInstanceRef.current.cell(ri, ci);
  //   console.log(thisCell);
  // };

  const getCellStyle = (ri, ci) => {
    const cellStyle = spreadsheetInstanceRef.current.cellStyle(ri, ci);
    return cellStyle;
  };

  return (
    <SpreadSheetContext.Provider
      value={{
        spreadsheetInstanceRef,
        spreadSheet_init,
        onCellSelected,
        onCellsSelected,
        onCellEdited,
        onCellStyle,
        onAddSheet,
        // onChanged,
       getCellStyle,
       currPos,
       setCurrPos,
      }}
    >
      {children}
    </SpreadSheetContext.Provider>
  );
};

export const useSpreadSheet = () => {
  return React.useContext(SpreadSheetContext);
};
