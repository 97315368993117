import { Avatar, Image,Button,message } from "antd";
import iconImage from "../../../assets/iconImage.png";
import classes from "./SharePrivacy.module.css";
// import "antd/dist/antd.css";
import { DropDownMenu } from "./DropDownMenu/DropDownMenu";
import ShareForm from "./ShareForm";
import {useParams} from 'react-router-dom'
import { useDocument } from "../../../Pages/SpreadsheetContainer/documentInfoContex";
import { workwiseServices } from "../../../Workw-Api/api";
import { emitAddMemebers } from "../../../socket";


export const SharePrivacy = ({ contentToggle,setIsModalVisible,privacy,setPrivacy }) => {
  const { documentInfo, setDocumentInfo}=useDocument()
  const isMobile = window.screen.width < 769;



  const mileGridName = documentInfo.name;

  const {id}=useParams()
  const privacyId=1
  const payload={attachmentId: id,name : mileGridName,privacyId}

  const callShareDocumentToAnyone=async()=>{
   await workwiseServices.shareDocumentToAnyone(payload)
    message.success("Document is shared as Public")
    setIsModalVisible(false)

    const response = await workwiseServices.getDocumentById(id);
    if (response?.status==200 && response.data?.data?.rightType !== 0 && response.data?.data) {

      setDocumentInfo(response.data.data);
    }

  }

  return (
    <div className={classes.ownerInfo}>
    <div className={classes.avatar} style={{marginTop: "12px"}} >
      <Avatar size="large" src={<Image src={iconImage} />} />
    </div>
    <div className={classes.info}>
      <div className={classes.name}>
        {contentToggle ? (
          <h4>Name</h4>
        ) : (
          <DropDownMenu
            menuItems={["Restricted", "Anyone in the Company"]}
            placement="bottomLeft"
            type={privacy}
            setType={setPrivacy}
          />
        )}
      </div>
      <div className={classes.email}>
        {privacy === "Restricted" ? (
          <>
            <ShareForm setIsModalVisible={setIsModalVisible} setPrivacy={setPrivacy} />
          </>
        ) : (
          <>
            <p style={{marginTop: "-0.8rem",fontSize:isMobile?'13px':'14px',width:'fit-content'}} >Share Access With Anyone in the Company
            </p>
            <Button style={{width:'fit-content', marginLeft: isMobile?'310px':'auto', display: 'block', backgroundColor: '#526bb1', color:'#ffffff', borderRadius: "8px"}} size="middle" className='share-button' htmlType="submit" onClick={callShareDocumentToAnyone} >
              Share
            </Button>
          </>
        )}
      </div>
    </div>
  </div>
  );
};
