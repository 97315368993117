import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { ChatBox } from "../../components/ChatBox/ChatBox";
import { useSpreadSheetRd } from "../../context/spreadsheet-context-rd";
import {
  listenerSingularCellSelection,
  listenerMultipleCellSelection,
  listenerEditedCellData,
  // listenerCellStyle,
  listenerAddSheet,
  listenerClients,
  listenerOnChanged,
  emitRoomID,
  listenerClientArrOnDisconnect,
  listenAddMember


  // emitOnChanged,
} from "../../socket";
import MouseContainer from "../mousecontainer";
import { workwiseServices } from "../../Workw-Api/api";
import { useDocument } from "./documentInfoContex";


export const SpreadsheetContainerRd = () => {
  const {setSheets,setDocumentInfo} = useDocument();
  

  const { id } = useParams();
  const { setCurrPos } = useSpreadSheetRd();
  
  // const konn = "http://192.168.18.81:8080";
  const konn = "https://milegrid.konnect.im";
  // const konn = "http://localhost:8080";
  
  const url_string = window.location.href; //window.location.href
  const url = new URL(url_string);
  const milegridURL = url.searchParams.get("gridname");
  let msg

  const {
    spreadSheet_init,
    spreadsheetInstanceRef,
    onCellSelected,
    onCellsSelected
    //   onChanged,
  } = useSpreadSheetRd();
  React.useEffect(() => {
    spreadSheet_init();
    onCellSelected(id);
    onCellsSelected(id)
    listenerAddSheet(spreadsheetInstanceRef);
    listenerSingularCellSelection(spreadsheetInstanceRef,msg='Reader');
    listenerEditedCellData(spreadsheetInstanceRef);
    listenerOnChanged(spreadsheetInstanceRef);
    listenerClients(spreadsheetInstanceRef)
    // listenerClientArrOnDisconnect();
    listenerMultipleCellSelection(spreadsheetInstanceRef)
    listenAddMember(()=>{
      setTimeout(async () => {
      
        const response = await workwiseServices.getDocumentById(id);
        if (response?.status == 200 && response.data?.data?.rightType !== 0 && response.data?.data) {
  
            setDocumentInfo(response.data.data)
            console.log('listenAddMember');
        }
  
      
    }, 2000)}
    )
  }, [
    //  onChanged,
    setCurrPos,
    onCellSelected,
    spreadSheet_init,
    spreadsheetInstanceRef,
    id,
  ]);

  // console.log(id);

  React.useEffect(() => {
    emitRoomID(id);
  }, [id]);

  React.useEffect(() => {
    const loadSpreadsheetData = async () => {
      try {
        document.getElementById("milegrid-url").innerHTML = milegridURL;
        // const response = await axios.get(
        //   `${konn}/api/${id}`
        // );
        const response = await axios.get(
          `${konn}/api/${id}`
        );
        // console.log(response.data[0].spreadsheetData);
        // if (response.data[0].spreadsheetData.length > 1) {
        //   response.data[0].spreadsheetData = response.data[0].spreadsheetData.slice(1);
        // }
        // console.log("S",response.data);
        let filteredSheetData;
        const getSheetData = response.data[0].spreadsheetData;
        // console.log("S",getSheetData);
        if (getSheetData) {
          filteredSheetData = getSheetData.filter(word => Object.keys(word).length > 0)
        }

        spreadsheetInstanceRef.current.loadData(
          filteredSheetData
        );
      } catch (error) {
        console.log(error);
      }
    };
    loadSpreadsheetData();
  }, [id, spreadsheetInstanceRef, milegridURL]);

  React.useEffect(() => {
    const createSpreadsheet = async () => {
      const response = await axios.post(
        `${konn}/api/create`,
        {
          id: id,
          data: {},
        }
      );
      // console.log(response);
    };
    createSpreadsheet();
  }, [id]);

  const [spreadsheetData, setSpreadsheetData] = React.useState([]);

  React.useEffect(() => {
    spreadsheetInstanceRef.current.change((data) => {
      setSpreadsheetData(data);
    });
  });

  React.useEffect(() => {
    const updateSpreadsheet = async (data) => {
      const response = await axios.post(
        `${konn}/api/update`,
        {
          spreadsheetID: id,
          spreadsheetData: data,
        }
      );
      // console.log(response);
    };

    let timer = setTimeout(async () => {
      updateSpreadsheet(spreadsheetData);
    }, 100);

    return () => clearTimeout(timer);
  }, [spreadsheetData, id]);

  return <div id="spreadsheet-container">
  <MouseContainer/>

    <ChatBox />
  </div>;
};
